import React, {
	useEffect,
	useRef,
	useState, useCallback
} from 'react';
import { format } from 'date-fns';
import { useAllSelector } from '../../services/selectors/allSelector';
import { submitPatientConsent } from '../../services/apis/pharmacy/submitPatientConsent';
import debounce from 'lodash/debounce';
import { Loader } from '../../components/common/loader';
import { ToPharmacy } from '../../components/form/selectInput';
import { DatePickers } from '../../components/form/datePicker';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { Signature } from '../../components/common/pharmacistSignature';
import { PatientConsentErrorMessage } from '../../components/common/AllConfirmationMessages';
import { fetchPatientConsentAutoFillNamesData } from '../../services/apis/dispense/patientSearch';

import * as routeName from '../../routes/routeName';
import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';

import avatar from '../../assets/images/userimg.png';

const PatientConsent = () => {

	const navigate = Hooks.useCustomNavigate();
	const dispatch = Hooks.useCustomDispatch();
	const sigRef = useRef();

	const initialFormData = { patientName: '', transferingForm: '', phn: '' };
	const [patientConsentDetail, setPatientConsentDetail] = useState(initialFormData);

	const [errorMessage, setErrorMessage] = useState({ patientName: '' });
	const [patientError, setPatientError] = useState({ patientSignature: '' });
	const [checkBoxError, setCheckBoxError] = useState({ checkBox: '' });

	const [patientSignature, setPatientSignature] = useState(null);
	const [selectedImageFile, setSelectedImageFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const [showMailModal, setShowMailModal] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const [showPatientSignature, setShowPatientSignature] = useState(false);
	const [visibleDocument, setVisibleDocument] = useState(false)

	const [selectedDate, setSelectedDate] = useState('');
	const [selectedImage, setSelectedImage] = useState(avatar);
	const [showToast, setShowToast] = useState(false)

	const { pharmacyId, driverSelectedPharmacy } = useAuthSelector()
	const { patientConsentResponse } = useAllSelector();


	const [suggestions, setSuggestions] = useState([]);
	const [phnSuggestions, setPhnSuggestions] = useState([]);


	const handalPatientConsent = (e) => {
		const { name, value } = e.target
		setPatientConsentDetail({
			...patientConsentDetail,
			[name]: value
		})
		const errorFields = ['patientName'];
		errorFields.forEach(field => {
			if (name === field) {
				setErrorMessage({ ...errorMessage, [field]: '' });
			}


		});

		if (name === 'patientName') {
			debouncedFetchSuggestions(value, 'name');
		} else if (name === 'phn') {
			debouncedFetchSuggestions(value, 'phn');
		}
	}



	const handleClearSignature = () => {
		sigRef?.current?.clear();
		setPatientSignature(null);
	}

	const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

	const handleSubmit = async (e) => {
		e.preventDefault();

		const requesteData = {
			pharmacy_id: pharmacyId,
			phn: patientConsentDetail?.phn,
			name: patientConsentDetail?.patientName,
			sign: patientSignature,
			dob: selectedDate,
			pharmacy_from: Number(patientConsentDetail?.transferingForm.value),
			setShowMailModal: setShowMailModal,
			setPatientConsentDetail: setPatientConsentDetail,
			setPatientSignature: setPatientSignature,
			patientConsentDetail: patientConsentDetail,
			handleClearSignature: handleClearSignature,
			setSelectedDate: setSelectedDate,
			setShowToast: setShowToast,
			profile_img: selectedImageFile,
			document_img: selectedFile,
			terms: isChecked ? 1 : 0,
			setSelectedFile: setSelectedFile,
			setSelectedImage: setSelectedImage,
			setSelectedImageFile: setSelectedImageFile,
		}

		const specificValidations = {
			patientName: 'Patient Name',
		};

		const allValidations = { ...specificValidations };

		const newErrorMessages = Object.fromEntries(
			Object.entries(allValidations).map(([field, message]) => [
				field,
				validateField(field, patientConsentDetail[field], message),
			])
		);

		setErrorMessage({
			...errorMessage,
			...newErrorMessages,
		});

		setPatientError({ ...patientError, patientSignature: !patientSignature ? 'Signature required' : null })
		setCheckBoxError({ ...checkBoxError, checkBox: !isChecked ? 'Select checkBox' : null })

		const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

		try {
			if (allDataPresent) {
				if (pharmacyId && isChecked && patientSignature) {
					dispatch(submitPatientConsent(requesteData))
				}
			}
		} catch (error) {
		}

	}
	useEffect(() => {
		if (isChecked === true && patientSignature && patientConsentDetail?.patientName) {
			setPatientError({ ...patientError, patientSignature: '' })
		}

	}, [isChecked])

	const displaySelectedImage = (event) => {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			setSelectedImageFile(file);
			if (file.type.startsWith('image/')) {
				const reader = new FileReader();

				reader.onload = function (e) {
					setSelectedImage(e.target.result);
				};

				reader.readAsDataURL(file);
			}
		}
	}


	const fetchSuggestions = useCallback(async (query, type) => {
		if (query.length < 1) {
			type === 'name' ? setSuggestions([]) : setPhnSuggestions([]);
			return;
		}
		try {

			const param = `?name=${query}&pharmacy_id=${pharmacyId}`;
			const action = await dispatch(fetchPatientConsentAutoFillNamesData(param));
			if (fetchPatientConsentAutoFillNamesData.fulfilled.match(action)) {
				const responseData = action.payload;
				if (responseData.success && Array.isArray(responseData.data)) {
					type === 'name' ? setSuggestions(responseData.data) : setPhnSuggestions(responseData.data);
				} else {
					type === 'name' ? setSuggestions([]) : setPhnSuggestions([]);
				}
			} else {
				type === 'name' ? setSuggestions([]) : setPhnSuggestions([]);
			}
		} catch (error) {
			type === 'name' ? setSuggestions([]) : setPhnSuggestions([]);
		}
	}, [dispatch, pharmacyId]);

	const debouncedFetchSuggestions = useCallback(
		debounce((query, type) => fetchSuggestions(query, type), 300),
		[fetchSuggestions]
	);

	useEffect(() => {
		return () => {
			debouncedFetchSuggestions.cancel();
		};
	}, [debouncedFetchSuggestions]);


	const handleFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};


	return (
		<>
			<div className="app-wrapper flex justify-center h-screen">
				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden "> */}
				{/* <div className="app-screen no-shades w-full xl:w-6/12 lg:w-4/12 md:w-6/12 sm:w-8/12 h-full md:h-auto overflow-y-hidden md:overflow-y-auto overflow-x-hidden"> */}
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden h-screen ">

					<div className="relative p-4">
						<div className="card bg-white p-4 rounded-lg shadow-sm">

							<div className="flex items-center gap-3">
								<div className="text-info flex-1">
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>

								<button onClick={() => navigate(`${routeName.DASHBOARD_PHARMACY}`)}>
									<Icons.HomeIcon />
								</button>
							</div>
						</div>

						<div className="title-row my-5">
							<h1 className="text-2xl font-bold">Patient Consent</h1>
						</div>

						<div className='flex flex-col'>
							<div style={{ width: 80, height: 80 }}>
								<img className='w-full h-full rounded-md object-cover'
									src={selectedImage}
									alt='avatar' />
							</div>
							<button onClick={() => {
								const filePicker = document.getElementById('selectProfileImage');
								filePicker.click();
							}} className='text-sm text-blue-700 flex itms-start font-medium pt-2 pb-2'>Patient Picture</button>
							<input name='avatar'
								onChange={displaySelectedImage}
								type='file' id="selectProfileImage" className='hidden' />
						</div>

						<form className="group">
							<div className="relative form-group mb-4">
								<div className="relative">
									<div className="absolute top-3 start-0 pl-3 flex items-center ps-3.5 pointer-events-none">
										<Icons.PatientNameIcon />
									</div>
									<input
										value={patientConsentDetail?.patientName}
										type="text"
										id="patientName"
										name="patientName"
										onChange={handalPatientConsent}
										className={`bg-gray-50 pl-9 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-8 p-2.5 [&:not(:placeholder-shown):invalid~span]:block invalid:[&:not(:placeholder-shown)]:border-red-500 valid:[&:not(:placeholder-shown)]:border-green-500 ${errorMessage?.patientName ? "border-red-600" : null}`}
										placeholder={`${errorMessage?.patientName ? "" : "Enter Patient Name"}`}
									/>
									{errorMessage?.patientName && <p className='text-sm text-red-600'>{errorMessage?.patientName}</p>}

									{suggestions.length > 0 && (
										<ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto">
											{suggestions.map((suggestion, index) => (
												<li
													key={index}
													className="px-2 py-1 hover:bg-gray-100 cursor-pointer text-gray-700"
													onClick={() => {
														setPatientConsentDetail({
															...patientConsentDetail,
															patientName: `${suggestion.PAGIVEN} ${suggestion.PASURNAME}`,
															phn: suggestion.PHN
														});
														setSelectedDate(format(suggestion.PABIRTH, 'yyyy-MM-dd'));
														setSuggestions([]);
													}}
												>
													<div>
														{`${suggestion.PAGIVEN} ${suggestion.PASURNAME} - ${format(new Date(suggestion.PABIRTH), 'd MMMM yyyy')
															}`}
													</div>
												</li>
											))}
										</ul>
									)}
								</div>
							</div>
							<div className="relative form-group mb-4">
								<div className="relative">
									<div className="absolute top-3 start-0 pl-2 flex items-center ps-3.5 pointer-events-none">
										<Icons.PhnIcon />
									</div>
									<input
										value={patientConsentDetail?.phn}
										type="text"
										id="phn"
										name="phn"
										onChange={handalPatientConsent}
										className={`bg-gray-50 pl-10 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5 [&:not(:placeholder-shown):invalid~span]:block invalid:[&:not(:placeholder-shown)]:border-red-500 valid:[&:not(:placeholder-shown)]:border-green-500 ${errorMessage?.phn ? "border-red-600" : null}`}
										placeholder={`${errorMessage?.phn ? "" : "Enter PHN"}`}
									/>
									{errorMessage?.phn && <p className='text-sm text-red-600'>{errorMessage?.phn}</p>}

									{phnSuggestions.length > 0 && (
										<ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto">
											{phnSuggestions.map((suggestion, index) => (
												<li
													key={index}
													className="px-2 py-1 hover:bg-gray-100 cursor-pointer text-gray-700"
													onClick={() => {
														setPatientConsentDetail({
															...patientConsentDetail,
															patientName: `${suggestion.PAGIVEN} ${suggestion.PASURNAME}`,
															phn: suggestion.PHN
														});
														setSelectedDate(format(suggestion.PABIRTH, 'yyyy-MM-dd'));
														setPhnSuggestions([]);
													}}
												>
													<div>
														{`${suggestion.PAGIVEN} ${suggestion.PASURNAME} - ${format(new Date(suggestion.PABIRTH), 'd MMMM yyyy')
															}`}
													</div>
												</li>
											))}
										</ul>
									)}
								</div>
							</div>
							<div className="relative mb-4">
								<DatePickers
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate} />
							</div>
							<div className="relative form-group mb-4">
								<div className="relative">
									<ToPharmacy patientConsentDetail={patientConsentDetail}
										setPatientConsentDetail={setPatientConsentDetail}
										errorMessages={errorMessage}
										setErrorMessages={setErrorMessage} />
								</div>
							</div>


							<div className="p-4 bg-gray-100 rounded-md mb-3">
								<div className="collapse-header flex items-center justify-between" onClick={() => setVisibleDocument(!visibleDocument)}>
									<h2>Upload Document</h2>
									{visibleDocument ?
										<Icons.PharmacistSignatureIcon2 /> :
										<Icons.PharmacistSignatureIcon1 />}
								</div>
								{visibleDocument &&
									<div className="mt-3">
										<div className="w-full relative flex items-center justify-center rounded-md">

											<div className="w-full">
												{selectedFile ? (<>
													<div className="flex flex-col break-all">
														<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
															{selectedFile.name}</p>
													</div>


													{selectedFile &&
														<button onClick={() => setSelectedFile(null)} type='button'
															className="text-xs  bg-red-50 bottom-2 text-red-500 right-2 rounded-md px-3 py-1 S gap-2 uppercase">
															Remove</button>}

												</>
												) : (
													<label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-54 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
														<div className="flex flex-col items-center justify-center pt-5 pb-6">
															<svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
																<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
															</svg>
															<p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
															<p className="text-xs text-gray-500 dark:text-gray-400"></p>
														</div>
														<input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} />
													</label>
												)}											</div>

										</div>
									</div>
								}
							</div>

							<Signature patientSignature={patientSignature} sigRef={sigRef} handleClearSignature={handleClearSignature}
								setPatientSignature={setPatientSignature}
								showPatientSignature={showPatientSignature}
								setShowPatientSignature={setShowPatientSignature} />


							{patientError?.patientSignature &&
								<p className='text-sm text-red-600 mb-2'>{patientError?.patientSignature}</p>}

							<div className="relative form-group mb-4">
								<div className="flex items-start">
									<input type="checkbox" onChange={(event) => { return setIsChecked(event.target.checked), setCheckBoxError({ checkBoxError: '' }) }}
										className={`shrink-0 mt-1 mr-2 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
										id="consent" name="consent
										${checkBoxError?.checkBox ? 'border-red-500' : null} `} />
									<label htmlFor="consent" className="text-sm text-gray-500 ms-3 dark:text-gray-400">I {patientConsentDetail?.patientName.charAt(0).toUpperCase() + patientConsentDetail?.patientName.slice(1)}, here by
										consent to {driverSelectedPharmacy?.name?.toUpperCase()} to transfer all my medications</label>
								</div>
							</div>

							<div className="relative mb-4 mt-6">
								<button
									type="button"
									onClick={(e) => handleSubmit(e)}
									className="text-white bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-3 me-2 mb-2 group-invalid:pointer-events-none group-invalid:opacity-30">
									{patientConsentResponse?.loading ? <p className='flex justify-center items-center'> <Loader />  </p> : ' Submit'}
								</button>
							</div>
						</form>
					</div>

					{showMailModal &&
						<div
							id="bottom-sheet-2" tabIndex="-1"
							style={{ backdropFilter: ' blur(2.5px)' }}
							className="fixed top-0 left-0 right-0 z-50 w-full bg-gray-900 bg-opacity-40 h-full">
							<div className="relative w-full h-full flex flex-col justify-end">

								<div
									className="relative max-h-full overflow-y-auto bg-white rounded-3xl rounded-bl-none rounded-br-none  shadow">
									<button type="button"
										onClick={() => setShowMailModal(false)}
										className="absolute top-1 right-1 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
										data-modal-hide="bottom-right-modal">
										<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
											viewBox="0 0 14 14">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
												strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
										</svg>
										<span className="sr-only">Close modal</span>
									</button>
									<div className="p-4 md:p-5 flex flex-col items-center">
										<div className="w-14 h-14 flex items-center justify-center rounded-full bg-green-100">
											<svg className="w-6 h-6" viewBox="0 0 30 30" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M13.6875 15.875L11.875 14.0937C11.6458 13.8645 11.3594 13.75 11.0156 13.75C10.6719 13.75 10.375 13.875 10.125 14.125C9.89583 14.3541 9.78125 14.6458 9.78125 15C9.78125 15.3541 9.89583 15.6458 10.125 15.875L12.8125 18.5625C13.0625 18.8125 13.3542 18.9375 13.6875 18.9375C14.0208 18.9375 14.3125 18.8125 14.5625 18.5625L19.875 13.25C20.125 13 20.2448 12.7083 20.2344 12.375C20.224 12.0416 20.1042 11.75 19.875 11.5C19.625 11.25 19.3281 11.1198 18.9844 11.1093C18.6406 11.0989 18.3438 11.2187 18.0938 11.4687L13.6875 15.875ZM10.1875 27.1875L8.375 24.125L4.9375 23.375C4.625 23.3125 4.375 23.151 4.1875 22.8906C4 22.6302 3.92708 22.3437 3.96875 22.0312L4.3125 18.5L1.96875 15.8125C1.76042 15.5833 1.65625 15.3125 1.65625 15C1.65625 14.6875 1.76042 14.4166 1.96875 14.1875L4.3125 11.5L3.96875 7.96872C3.92708 7.65622 4 7.36976 4.1875 7.10934C4.375 6.84892 4.625 6.68747 4.9375 6.62497L8.375 5.87497L10.1875 2.81247C10.3542 2.54163 10.5833 2.35934 10.875 2.26559C11.1667 2.17184 11.4583 2.18747 11.75 2.31247L15 3.68747L18.25 2.31247C18.5417 2.18747 18.8333 2.17184 19.125 2.26559C19.4167 2.35934 19.6458 2.54163 19.8125 2.81247L21.625 5.87497L25.0625 6.62497C25.375 6.68747 25.625 6.84892 25.8125 7.10934C26 7.36976 26.0729 7.65622 26.0312 7.96872L25.6875 11.5L28.0312 14.1875C28.2396 14.4166 28.3438 14.6875 28.3438 15C28.3438 15.3125 28.2396 15.5833 28.0312 15.8125L25.6875 18.5L26.0312 22.0312C26.0729 22.3437 26 22.6302 25.8125 22.8906C25.625 23.151 25.375 23.3125 25.0625 23.375L21.625 24.125L19.8125 27.1875C19.6458 27.4583 19.4167 27.6406 19.125 27.7343C18.8333 27.8281 18.5417 27.8125 18.25 27.6875L15 26.3125L11.75 27.6875C11.4583 27.8125 11.1667 27.8281 10.875 27.7343C10.5833 27.6406 10.3542 27.4583 10.1875 27.1875Z"
													fill="#009989" />
											</svg>
										</div>
										<p className="text-lg font-semibold">Form submitted successfully.</p>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

			{showToast && <PatientConsentErrorMessage />}

			<div className='h-24'></div>
		</>
	)
}

export default PatientConsent
