import React, { useState, useEffect } from 'react'
import { ContactIcon, contactIcon2, MessageIcon, MessageIcon2 } from '../../assets/images'

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useChatBoxSelector } from '../../services/selectors/chatBoxSelectores';

import { getRecentChatsList } from '../../services/slices/chat/getRecentChatsList';
import { useAuthSelector } from '../../services/selectors/authSelector';
import { getContactList } from '../../services/slices/chat/getcontactList';

import { readUnreadMessages } from '../../services/slices/chat/readUnreacMessages';
import { useAllSelector } from '../../services/selectors/allSelector';
import { driverLogout } from '../../services/apis/pharmacy/logout';

import { getActivityStatus } from '../../services/slices/chat/activityStatus';
import { useCustomLocation } from '../../hooks';

import * as Icons from '../../assets/svg/icons';
import Sheet from 'react-modal-sheet';

const ChatListLoader = () => {

    return (
        <div className="">
            {[...Array(6)].map((_, index) => (
                <div className="flex items-center space-x-4 border border-b-0 border-gray-200 p-4 " key={index}>
                    <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
                    <div className="flex-1 space-y-2">
                        <div className="w-24 h-4 bg-gray-300 rounded animate-pulse"></div>
                        <div className="w-32 h-3 bg-gray-200 rounded animate-pulse"></div>
                    </div>
                    <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                </div>
            ))}
        </div>
    )
}

const OpenChatSheet = ({ showModel, setShowModel }) => {

    const dispatch = useDispatch()

    const { getRecentChatsListResponse, getContactListResponse } = useChatBoxSelector()
    const { authPharmacistId } = useAuthSelector()

    const recentChatsList = getRecentChatsListResponse?.data?.latest_messages
    const contactList = getContactListResponse?.data?.data

    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState('recent_chats');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // const handleNavigate = (id, type, value) => {

    //     const requestData = {
    //         group_id: value.group_id,
    //         mark_as_read: 0
    //     }

    //     navigate(`initated-chat?id=${id}&type=${type}`)
    //     setShowModel(false)
    //     if (value.unread_count != 0) {
    //         dispatch(readUnreadMessages(requestData))
    //     }
    // }

    const handleNavigate = (id, type, value) => {

        // Open the URL in a new tab
        window.open(`initated-chat?id=${id}&type=${type}`, '_blank');

        // setShowModel(false);

        // Dispatch action if there are unread messages
        if (value?.unread_count !== 0) {
            const requestData = {
                group_id: value?.group_id,
                mark_as_read: 0
            };
    
            dispatch(readUnreadMessages(requestData));
        }
    };

    const seenUserIds = new Set();

    return (
        <Sheet isOpen={showModel} onClose={() => setShowModel(false)}>
            <Sheet.Container>
                <Sheet.Content>
                    <div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
                        <h3 className="text-sm font-bold text-gray-900">
                            {activeTab === 'recent_chats' ? 'Messaging' : 'Contacts'}
                        </h3>

                        <button type="button"
                            onClick={() => { return setShowModel(false) }}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-hide="bottom-right-modal">
                            <Icons.CloseModelIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className='overflow-auto' style={{ height: '400px', WebkitOverflowScrolling: 'touch' }}>
                        {/* ---------------------------------------Recent Chat Section ---------------------- */}
                        {activeTab === 'recent_chats' && (
                            <div className="">
                                {getRecentChatsListResponse.loading ?
                                    <ChatListLoader />
                                    :
                                    getRecentChatsListResponse?.error !== null ?
                                        <p className='text-center font-medium text-red-500'>Something Went wrong please try again later.</p>
                                        :
                                        recentChatsList?.length === 0 ?
                                            <h4 className="text-center text-red-500">You haven't started any chats yet.</h4>
                                            :
                                            recentChatsList?.map((value, index) => {
                                                if (value?.user_id?.length === 0 || seenUserIds.has(value?.user_id[0])) return null;

                                                // Mark user_id[0] as seen
                                                seenUserIds.add(value?.user_id[0]);

                                                return (
                                                    value?.user_id?.length === 0 ? null :
                                                        authPharmacistId === value?.user_id[0] ? null :
                                                            <div className="flex items-center bg-white p-3 border border-gray-100" key={index} onClick={() => { return handleNavigate(value?.user_id[0], 'recent', value) }}>
                                                                <div className="relative">
                                                                    <div className="flex -space-x-2">
                                                                        <img className="w-12  h-12  rounded-full border-2 border-white" src={value?.thumbnail} alt="User 1" />
                                                                    </div>

                                                                    {
                                                                        value.online_status === 'online' ?
                                                                            <span className="absolute bottom-0 right-0 w-3.5 h-3.5 bg-green-500 border-2 border-white rounded-full"></span>
                                                                            : value.online_status === 'offline' ?
                                                                                <span className="absolute bottom-0 right-0 w-3.5 h-3.5 bg-red-500 border-2 border-white rounded-full"></span>
                                                                                : value.online_status === 'idle' ?
                                                                                    <span className="absolute bottom-0 right-0 w-3.5 h-3.5 bg-yellow-500 border-2 border-white rounded-full"></span>
                                                                                    : null
                                                                    }
                                                                </div>

                                                                <div className="ml-3 flex-1">
                                                                    <h2 className="text-base font-medium">{value?.name}</h2>
                                                                    <div className="text-sm text-gray-500">
                                                                        {
                                                                            value?.latest_message === null ? null : <>
                                                                                {
                                                                                    value?.latest_message?.message_media?.length !== 0 ?
                                                                                        <div className=" text-gray-500 flex">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                                            </svg>
                                                                                            <span style={{ marginLeft: '3px' }}>Photo</span>
                                                                                        </div>
                                                                                        :

                                                                                        value?.latest_message?.media_pdf?.length !== 0 ?
                                                                                            <div className=" text-gray-500 flex">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                                                                </svg>
                                                                                                <span style={{ marginLeft: '3px' }}>Attachement</span>
                                                                                            </div>
                                                                                            :
                                                                                            <div className=" text-gray-500 break-all">
                                                                                                {value?.latest_message?.message_text?.split(' ')?.slice(0, 3)?.join(' ')}
                                                                                            </div>
                                                                                }
                                                                            </>
                                                                        }

                                                                    </div>
                                                                </div>

                                                                <div className="text-right pb-4">
                                                                    {
                                                                        value?.latest_message === null ? null : <>
                                                                            <p className="text-xs text-gray-400">
                                                                                {value?.latest_message.update_time}
                                                                            </p>
                                                                        </>
                                                                    }

                                                                    {value?.unread_count == 0 ? null :
                                                                        <div className="relative">
                                                                            <span className="absolute top-0 right-0 text-white bg-red-500 rounded-full px-2 mt-1 py-0.5 text-xs">
                                                                                {value?.unread_count}
                                                                            </span>
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                )
                                            })}
                            </div>
                        )}

                        {/* ---------------------------------------Contact Chats Section ---------------------- */}

                        {activeTab === 'contacts_list' && (
                            <div className="">
                                {getContactListResponse.loading ?
                                    <ChatListLoader />
                                    :
                                    getContactListResponse?.error !== null ?
                                        <p className='text-center font-medium text-red-500'>Something Went wrong please try again later.</p>
                                        :
                                        contactList?.length === 0 ?
                                            <h4 className="text-center text-red-500">No Contacts Found.</h4>
                                            :
                                            <>
                                                {contactList?.map((value, index) => {
                                                    return (
                                                        authPharmacistId === value?.user_id ? null :
                                                            <div className="flex items-center bg-white p-3 border border-gray-100" key={index}
                                                                // onClick={() => { return setShowModel(false), navigate(`initated-chat?id=${value?.user_id}&type=contact`) }}
                                                                onClick={() => { return handleNavigate(value?.user_id, 'contact', null) }}
                                                            >
                                                                <div className="relative">
                                                                    <div className="flex -space-x-2">
                                                                        <img className="w-12  h-12  rounded-full border-2 border-white" src={value?.thumbnail} alt="User 1" />
                                                                    </div>
                                                                </div>

                                                                <div className="ml-3 flex-1">
                                                                    <h2 className="text-base font-medium">{value?.name}</h2>
                                                                    <p className='text-gray-500' style={{ fontSize: '10px' }}>Joined at {value.join_at}</p>
                                                                </div>
                                                            </div>
                                                    )

                                                })}
                                            </>}
                            </div>
                        )}

                        {/* --------------------------------------- Tabs ---------------------- */}

                        <div className="absolute border border-t-1 py-2 bottom-0 w-full bg-white border-b border-gray-200">
                            <ul className="flex flex-wrap items-center justify-center -mb-px text-sm font-medium text-center" role="tablist">
                                <li className="me-2  " role="presentation">
                                    <button className={`flex flex-col items-center justify-center px-5  ${activeTab === 'recent_chats'
                                        ? 'text-green-500 rounded-lg '
                                        : ''
                                        }`} onClick={() => handleTabClick('recent_chats')}
                                        type="button"
                                        role="tab"
                                    >
                                        {activeTab === 'recent_chats' ?
                                            <img src={MessageIcon2} alt='message' className='w-8 ' />
                                            :
                                            <img src={MessageIcon} alt='message' className='w-8 ' />
                                        }
                                        <span className=''>Chats</span>
                                    </button>
                                </li>

                                <li className="me-2 flex items-center justify-center" role="presentation">
                                    <button className={`flex flex-col items-center justify-center px-5  ${activeTab === 'contacts_list'
                                        ? 'text-green-500 rounded-lg'
                                        : ''
                                        }`} onClick={() => handleTabClick('contacts_list')}
                                        type="button"
                                        role="tab"
                                    >
                                        {activeTab === 'contacts_list' ?
                                            <img src={contactIcon2} alt='message' className='w-8 ' />
                                            :
                                            <img src={ContactIcon} alt='message' className='w-8 ' />
                                        }

                                        <span>Contacts</span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className='h-24'></div>

                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet >
    )
}


export const ChatBox = () => {

    const location = useCustomLocation()
    const navigate = useNavigate()

    const { getRecentChatsListResponse } = useChatBoxSelector()
    const { driverLogoutResponse } = useAllSelector();

    const recentChatsList = getRecentChatsListResponse?.data?.latest_messages

    const [showChat, setShowChat] = useState(false)
    const dispatch = useDispatch()

    const handleShowChat = () => {
        setShowChat(true)
        dispatch(getRecentChatsList())
        dispatch(getContactList())
    }

    const totalUnreadMessagesCount = recentChatsList?.reduce((acc, value, index) => {
        return acc + value.unread_count;
    }, 0);

    useEffect(() => {
        dispatch(getRecentChatsList())
        return () => {
        }
    }, [dispatch])

    const handleLogout = () => {
        dispatch(getActivityStatus({ status: 'offline' }))
        dispatch(driverLogout())
    }

    return (
        <>
            {/* <button onClick={handleShowChat} className={`z-10 fixed bottom-4 right-4 bg-white text-white p-2 rounded-full shadow-lg lg:block sm:block md:block`}>
                <img src={chatIcon} alt='chat' className='w-10 h-10' />
            </button> */}

            {location.pathname === '/verify-otp' ? null :
                <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200">
                    <div className="flex justify-around py-3">
                        <div className="flex flex-col items-center text-sm" onClick={() => navigate('/dashboard-pharmacy')}>
                            {/* <img src={homeIcon} alt='home' className='w-6 h-6' style={{color: 'red'}} /> */}

                            <Icons.HomeIcons color='#6b7280' />

                            <span className="" style={{ color: '#6b7280' }}>Home</span>
                        </div>

                        <div className="relative flex flex-col items-center text-sm" onClick={handleShowChat}>
                            <Icons.MessageIcon color='#6b7280' />
                            <span className="text-gray-500">Messages</span>
                            {totalUnreadMessagesCount !== 0 && totalUnreadMessagesCount !== undefined && totalUnreadMessagesCount !== null &&
                                <span className="absolute -top-2 right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">{totalUnreadMessagesCount}</span>}
                        </div>

                        <div className="flex flex-col items-center text-sm" onClick={handleLogout}>
                            <Icons.LogoutIcon color='#6b7280' />

                            <span className="text-gray-500">
                                {driverLogoutResponse?.loading ? 'Processing...' : 'Log out'}
                            </span>
                        </div>
                    </div>
                </div>
            }

            <OpenChatSheet showModel={showChat} setShowModel={setShowChat} />
        </>
    )
}
