export const apiKey = process.env.REACT_APP_ABLY_API_KEY;

export const chatChannelName = 'oat-pharmacy-chat-channel'


export const currentDeliveryStartChannel = process.env.REACT_APP_CURRENT_DELVIERY_START;
export const currentDeliveryEndChannel = process.env.REACT_APP_CURRENT_DELIVERY_END;
export const deliveryDriverDeliveredDispense = process.env.REACT_APP_DELIVERY_DRIVER_DELIVERED_DISPENSE;

export const oatPharmacyInspectionMode = process.env.REACT_APP_OAT_PHARMACY_INSPECTION_MODE;

export const oat_driver_login = 'oat-driver-login';
export const oat_driver_passcode_verify = 'oat-driver-passcode-verify';
export const driver_pharmacy = 'driver/driver-pharmacy';

export const change_password = 'driver/setting/change-password';
export const change_passcode = 'driver/setting/change-passcode';
export const driver_logout = 'driver/logout';

export const driver_get_route_list = 'driver/get-route-list';
export const driver_get_patients = 'driver/get-patients';
export const driver_get_delivered_patients = 'driver/deliverd-patient-list';

export const driver_patient_search = 'driver/patient-search';
export const driver_get_pharmacist = 'driver/get-pharmacist-with-route';
export const driver_patient_delivery = 'driver/patient-delivery';

export const driver_get_all_pending_list = 'driver/get-all-pending-list';
export const driver_confirm_delivery = 'driver/confirm-delivery';
export const update_profile = 'driver/setting/update-driver-profile';

export const driver_driver_final_missed = 'driver/driver-final-missed';
export const driver_driver_today_missed = 'driver/driver-today-missed';
export const driver_update_delivery_status = 'driver/update-delivery-status';

export const driver_delivered_route_list = 'driver/driver-deliverd-route';
export const today_missed = 'driver/driver-missed-list';
export const driver_submit_consent = 'driver/submit-consent';

export const driver_all_pharmacy_list = 'driver/all-pharmacy-list';
export const driver_get_Pharmacy = 'driver/get-Pharmacy'

export const driver_SOS_emergency = 'driver/sos-emergency'

export const driver_supervisor_analytics = 'driver/supervisor-analytics'
export const driver_patient_newrx = 'driver/patient-newrx'
export const update_newrx_option = 'driver/update-newrx-option'
export const patient_prescription = 'driver/patient-prescription'
export const patient_without_pharmacist_sign = 'driver/patient-without-pharmacist-sign'

export const save_pharmasist_sign = 'driver/save-pharmasist-sign'
export const update_patient_profile = 'driver/update-patient-profile'

export const set_temp_driver_pharmacist = 'driver/set-temp-driver-pharmacist'

export const is_return_validate = 'driver/is-return-validate'

export const upload_triplicates_patient_sign = 'driver/upload-triplicates-patient-sign'

export const who_viewed_med = 'driver/view-patients-create-log';

export const search_patient = 'driver/search-consent-patient'

export const otp_verification = 'driver/submit-otp'

export const driver_get_recent_chats = 'driver/chat/driver-get-recent-chats'
export const driver_chat_users = 'driver/chat/driver-chat-users'

export const driver_chat_initated = 'driver/chat/driver-intiate-chat'
export const driver_add_message_to_group = 'driver/chat/driver-add-messages-to-group'

export const pharmacy_read_messages = 'driver/chat/pharmacy-read-messages'

export const driver_update_tu_online_status = 'driver/chat/driver-update-tu-online-status'










