import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { driver_update_tu_online_status  } from "../../../networking/urlEndPoints";

export const getActivityStatus = createAsyncThunk('getActivityStatus', async (requestData, { rejectWithValue }) => {
    try {
        const response = await Axios.post(driver_update_tu_online_status , requestData)
        const data = response.data;
        return data;
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const initialState = {
    loading: false,
    data: null,
    error: null
}

const getActivityStatusSlice = createSlice({
    name: 'getContactList',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(getActivityStatus.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(getActivityStatus.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(getActivityStatus.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default getActivityStatusSlice.reducer