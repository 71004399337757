import React, { useEffect, useState, useRef } from 'react';

import {
    backIcon,
    sendMessageIcon
} from '../../assets/images';

import {
    getInitatedChatBox,
    removeAllMessages
} from '../../services/slices/chat/initatedChatBox';

import {
    AuthorSendMessage,
    AuthorReceiveMessage,
    InitatedChatBoxError,
    AuthorSendImage,
    AuthorReceiveImage,
    AuthorSendPdf,
    AuthorReceivePdf
} from './messages';

import {
    handleViewportResize,
    scrollToTop,
    subscribeGroupMessages,
    usePreventZoom
} from './helper';

import {
    seenMessageTune,
    sendMessageTune
} from '../../assets/audio';

import { Chatloader } from './loaders';
import { useDispatch } from 'react-redux';

import { handleAddMessageToGroup } from '../../services/slices/chat/addMessageToGroup';
import { apiKey } from '../../networking/urlEndPoints';

import { ScrollLoader } from '../common/loader';
import { useAuthSelector } from '../../services/selectors/authSelector';

import { useLocation } from 'react-router-dom';
import { getCurrentTimeCanada } from '../../utils/helper/helper';
import { useChatBoxSelector } from '../../services/selectors/chatBoxSelectores';
import { CameraIcon } from '../../assets/svg/icons';

import Sheet from 'react-modal-sheet';
import * as Icons from '../../assets/svg/icons';

import Ably from 'ably';
import InfiniteScroll from 'react-infinite-scroll-component';
// import VoiceRecorder from './voiceRecording';
import _ from 'lodash';

const OpenImageModal = ({ mediaType, showModel, setShowModel, selectedImage, setSelectedImage, inputRef, setChatInput, chatInput, submitMessages, selectedPdf, setSelectedPdf }) => {

    return (
        <Sheet isOpen={showModel} onClose={() => setShowModel(false)}>
            <Sheet.Container>
                <Sheet.Content>
                    <div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
                        <h3 className="text-sm font-bold text-gray-900">

                        </h3>

                        <button type="button"
                            onClick={() => { return setShowModel(false), setSelectedImage([]), setChatInput(''), setSelectedPdf([]) }}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-hide="bottom-right-modal">
                            <Icons.CloseModelIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className='overflow-auto p-4' >
                        {/* {mediaType === 'voice' ? <VoiceRecorder /> : <> */}

                        {selectedImage?.length !== 0 && (
                            selectedImage?.map((image, index) => (
                                <div key={index} className='w-full h-full mb-4'>
                                    <img src={URL.createObjectURL(image)} alt="Selected" className='w-full h-full rounded-md' />
                                </div>
                            ))
                        )}

                        {selectedPdf?.length !== 0 && (
                            selectedPdf?.map((image, index) => (
                                <div key={index} className='w-10 border border-gray-300 rounded-md mb-4 w-full mt-4 p-2'>
                                    <div className='flex items-center'>
                                        <Icons.PDFIcon />
                                        <p className='text-sm text-gray-500'>{image.name}</p>
                                    </div>
                                </div>
                            ))
                        )}
                        {/* </>} */}
                        <div className='h-10'></div>
                    </div>

                    <div className="p-4 border-t border-gray-200 bg-white fixed bottom-0 w-full shadow-lg z-50">
                        <div className="flex items-center space-x-3" >
                            <div className='flex-1 relative border p-4 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500' >
                                <div

                                    contentEditable
                                    ref={inputRef}
                                    onInput={(e) => {
                                        e.preventDefault();
                                        const text = e.currentTarget.textContent;
                                        setChatInput(text);
                                    }}
                                    className="overflow-y-auto overflow-x-hidden text-sm max-h-16  shadow-none border-0 border-transparent focus:outline-none focus:ring-2 focus:ring-transparent"
                                    suppressContentEditableWarning={true}
                                ></div>
                            </div>

                            {!chatInput && (
                                <span className="absolute text-gray-400  top-1/2 pl-1 transform -translate-y-1/2 pointer-events-none">
                                    Add a caption
                                </span>
                            )}

                            <button className='bg-green-500 rounded-full p-2' onClick={submitMessages} >
                                <img src={sendMessageIcon} alt="sendMessageIcon" className='w-6 h-6' />
                            </button>
                        </div>
                    </div>
                    <div className='h-10'></div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet >
    )
}


const ChatInterface = () => {

    const dispatch = useDispatch()

    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);
    const headerRef = useRef(null);
    const galleryInputRef = useRef(null);
    const documentInputRef = useRef(null);

    const location = useLocation();

    const { authPharmacistId } = useAuthSelector()
    const { getRecentChatsListResponse, getInitatedChatBoxResponse, getContactListResponse } = useChatBoxSelector()

    const recentChatsList = getRecentChatsListResponse?.data?.latest_messages
    const allMessages = getInitatedChatBoxResponse?.allMessages

    const contactList = getContactListResponse?.data?.data

    const user_id = Number(new URLSearchParams(location.search).get('id'))
    const chatType = new URLSearchParams(location.search).get('type')

    const [myInputMessages, setMyInputMessages] = useState([]);

    const [chatInput, setChatInput] = useState('')
    const [currentChatUser, setCurrentChatUser] = useState(null)
    const [apiCallMade, setApiCallMade] = useState(false)

    const [chatAppendedMessages, setChatAppendedMessages] = useState([])

    const [hasMore, setHasMore] = useState(true);
    const [loadMoreData, setLoadMoreData] = useState(false)

    const currentTime = getCurrentTimeCanada()
    const group_id = getInitatedChatBoxResponse?.data?.data?.group_id
    const paginatinData = getInitatedChatBoxResponse?.data?.data
    const initatedChatUser = getInitatedChatBoxResponse?.onlineStatus?.find(user => user?.user_id === user_id)
    const [selectedImage, setSelectedImage] = useState([]);

    const [keyboardOpen, setKeyboardOpen] = useState(false)
    const [showImageModal, setShowImageModal] = useState(false)
    const [overlayImage, setOverlayImage] = useState(null)
    const [selectedPdf, setSelectedPdf] = useState([])

    const [showAttachment, setShowAttachment] = useState(false)
    const [mediaType, setMediaType] = useState(null)


    const submitMessages = (e) => {

        e.preventDefault()

        if ((chatInput || selectedImage?.length !== 0 || selectedPdf?.length !== 0) && group_id) {
            setShowImageModal(false)
            const formData = new FormData();
            formData.append('group_id', group_id)

            if (chatInput) {
                formData.append('message_text', chatInput ? chatInput : '')
                formData.append('message_type', 'text')
            }

            if (selectedImage?.length !== 0) {
                selectedImage?.forEach((file, index) => {
                    formData?.append(`message_media[${index}]`, file);
                });
                formData.append('message_type', 'media')
            }

            if (selectedPdf?.length !== 0) {
                selectedPdf?.forEach((file, index) => {
                    formData?.append(`media_pdf[${index}]`, file);
                });
                formData.append('message_type', 'media')
            }
            dispatch(handleAddMessageToGroup(formData))
            setMyInputMessages([...myInputMessages, { message: chatInput, sender: 'user', message_media: selectedImage, message_pdf: selectedPdf, time: currentTime }])
            setChatInput('')
            setSelectedImage([])
            setSelectedPdf([])
            inputRef.current.textContent = '';
            inputRef.current.focus();
            const audio = document.getElementById('messageSound');
            audio.play();
        }
    }

    useEffect(() => {
        if (recentChatsList && chatType === 'recent') {
            const currentUser = recentChatsList?.find(chat => chat?.user_id[0] === user_id);
            setCurrentChatUser(currentUser)
        }
        else if (chatType === 'contact') {
            const currentUser = contactList?.find(chat => chat?.user_id === user_id);
            setCurrentChatUser(currentUser)
        }
    }, [recentChatsList, user_id, chatType, contactList])

    useEffect(() => {
        if (user_id && !apiCallMade) {
            const requestedData = {
                group_type: 'one_to_one',
                user_id: [user_id],
                create_group: 0
            }
            setLoadMoreData(true)
            dispatch(removeAllMessages())
            setChatAppendedMessages([])
            dispatch(getInitatedChatBox(requestedData))
            setApiCallMade(true)
        }
        return () => { }
    }, [dispatch, user_id, apiCallMade])

    useEffect(() => {
        if (myInputMessages.length > 0) {
            scrollToTop(chatContainerRef);
        }
    }, [myInputMessages, chatContainerRef]);

    useEffect(() => {
        const cleanup = handleViewportResize(headerRef);

        return () => {
            cleanup();
        };
    }, []);

    const playMessageSound = () => {
        const audio = document.getElementById('sendMessageTune');
        audio.play();
    }

    useEffect(() => {
        const ably = new Ably.Realtime({ key: apiKey });
        const ablyConnection = subscribeGroupMessages(group_id, setChatAppendedMessages, setMyInputMessages, authPharmacistId, playMessageSound)
        return () => {
            if (ably.connection.state === 'connected') {
                ablyConnection.close();
            }
        };
    }, [group_id, authPharmacistId])

    const fetchMoreData = () => {
        setLoadMoreData(false)
        if (getInitatedChatBoxResponse?.currentPage >= paginatinData?.pagination?.last_page) {
            setHasMore(false);
            return;
        }

        const requestedData = {
            group_type: 'one_to_one',
            user_id: [user_id],
            create_group: 0,
            page: getInitatedChatBoxResponse.currentPage + 1
        }
        dispatch(getInitatedChatBox(requestedData))
    };


    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImage(prevImages => [...prevImages, ...files]);
        if (files.length > 0) {
            setShowImageModal(true);
        }
    };

    const handleGalleryUpload = (event) => {
        setShowAttachment(false)
        const files = Array.from(event.target.files);
        setSelectedImage(prevImages => [...prevImages, ...files]);
        if (files.length > 0) {
            setShowImageModal(true);
        }
    };


    const handlePdfUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedPdf(prevImages => [...prevImages, file]);
            setShowAttachment(false);
            setShowImageModal(true);
        } else {
            alert('Please select a PDF file.');
        }
    }

    usePreventZoom()


    const toolbarWrapRef = useRef(null);
    const toolbarRef = useRef(null);
    const editorRef = useRef(null);

    const [fixPosition, setFixPosition] = useState(0);

    // Function to set the margin for showing the toolbar if hidden
    const setMargin = () => {
        if (!toolbarWrapRef.current || !toolbarRef.current) return;

        const newPosition = toolbarWrapRef.current.getBoundingClientRect().top;

        if (newPosition < -1) {
            // Show the toolbar by setting a new margin
            toolbarRef.current.classList.add('down');
            let newFixPosition = Math.abs(newPosition);

            // Adjust margin if at the bottom of the page to avoid a gap
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                newFixPosition -= 2;
            }

            // Update toolbar's margin-top
            setFixPosition(newFixPosition);
            toolbarRef.current.style.marginTop = newFixPosition + 'px';
        }
    };

    // Debounce the setMargin function to avoid frequent calls on scroll
    const debounceMargin = _.debounce(setMargin, 150);

    // Function to reset the toolbar and check its position again
    const showToolbar = () => {
        if (!toolbarRef.current) return;

        // Reset the toolbar to default position
        if (fixPosition > 0) {
            toolbarRef.current.classList.remove('down');
            setFixPosition(0);
            toolbarRef.current.style.marginTop = '0px';
        }

        // Call debounceMargin to set the new position if needed
        debounceMargin();
    };

    // Attach scroll and blur event listeners
    useEffect(() => {
        // Attach event listeners to window and editor
        window.addEventListener('scroll', showToolbar);

        if (editorRef.current) {
            editorRef.current.addEventListener('blur', showToolbar);
        }

        // Cleanup function to remove event listeners on component unmount
        return () => {
            window.removeEventListener('scroll', showToolbar);
            if (editorRef.current) {
                editorRef.current.removeEventListener('blur', showToolbar);
            }
        };
    }, [fixPosition, debounceMargin]);






    return (<>

        {!user_id && !chatType ?

            <>
                <div className='h-screen w-full flex items-center justify-center'>
                    <div className='text-center'>
                        <p className='text-gray-500'>Looks Like You are not connected to any user</p>
                    </div>
                </div>
            </>

            :
            <>
                <div className="app-wrapper flex justify-center">
                    <div className="w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden">


                        <div className="flex flex-col max-w-md mx-auto bg-white h-screen rounded-lg">
                            <div onClick={() => { setKeyboardOpen(false); setShowAttachment(false) }} className={`flex items-center ${keyboardOpen ? 'p-8' : 'pl-4'} -ml-2 justify-between border-b border-gray-200 fixed top-0 w-full z-50 bg-white`} ref={headerRef} style={{ zIndex: '99999', position: 'fixed !important', top: '0px' }}>
                                <div className="flex items-center space-x-3" id="toolbar-wrap" ref={toolbarWrapRef}>
                                    <div id={keyboardOpen ?  'toolbar' : ''} ref={toolbarRef} className='w-full flex items-center gap-1'>
                                        <img src={backIcon} alt="backIcon" className='w-6 h-6 ' onClick={() => window.history.back()} />
                                        <img className="w-10 h-10 rounded-full ml-2" src={currentChatUser?.thumbnail} alt="Jhon Abraham" />
                                        <div>
                                            <h4 className="font-semibold text-gray-900">{currentChatUser?.name}</h4>
                                            {initatedChatUser?.online_status === 'online' ?
                                                <p className="text-sm text-green-500">Active now</p>
                                                :
                                                initatedChatUser?.online_status === 'offline' ?
                                                    <p className="text-sm text-yellow-500">Offline</p> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {keyboardOpen && <div className='mt-96'></div>}


                            {/* /-------------Body Section-------------/ */}
                            <div className='h-full py-20 overflow-auto mb-2' onTouchEndCapture={() => { setKeyboardOpen(false); setShowAttachment(false) }} ref={chatContainerRef} id="scrollableDiv" style={{ display: 'flex', flexDirection: 'column-reverse' }}>

                                <div className="flex-1 p-2 " >

                                    {getInitatedChatBoxResponse.loading && loadMoreData ?
                                        <>
                                            {[...Array(6)].map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <Chatloader />
                                                </React.Fragment>
                                            ))}
                                        </>
                                        : getInitatedChatBoxResponse.error !== null ?
                                            <InitatedChatBoxError />
                                            :
                                            <>

                                                <InfiniteScroll
                                                    dataLength={paginatinData?.pagination?.total || 0}
                                                    next={fetchMoreData}
                                                    hasMore={hasMore}
                                                    inverse={true}
                                                    scrollableTarget="scrollableDiv"
                                                    loader={<div className='flex justify-center items-center my-10 fixed top-10 w-full'>{!hasMore && <ScrollLoader />}</div>}
                                                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                                >

                                                    {allMessages && Object?.keys(allMessages)?.map((date, index) => (
                                                        <div key={index}>
                                                            <p className="text-center  my-2 bg-purple-50 font-medium text-gray-700 rounded-lg w-32 flex items-center justify-center mx-auto text-sm">{date.charAt(0).toUpperCase() + date.slice(1)}</p>

                                                            {allMessages[date]?.map((message, messageIndex) => (
                                                                <React.Fragment key={messageIndex}>
                                                                    {message.message_text ? (
                                                                        message.user_id === user_id ? (
                                                                            <AuthorReceiveMessage
                                                                                message={message.message_text}
                                                                                messageTime={message.date_and_time?.slice(12)} // Adjust message time as needed
                                                                                name={currentChatUser?.name}
                                                                                photo={currentChatUser?.thumbnail}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <AuthorSendMessage
                                                                                    message={message.message_text}
                                                                                    messageTime={message.date_and_time?.slice(12)}
                                                                                />
                                                                            </>
                                                                        )
                                                                    ) : null}

                                                                    {message?.message_media?.length > 0 ? (
                                                                        message.user_id === user_id ? (
                                                                            <>
                                                                                {message?.message_media?.map((image, index) => (
                                                                                    <span key={index} onClick={() => setOverlayImage(image)}>
                                                                                        <AuthorReceiveImage message={image} messageTime={message.date_and_time?.slice(12)} />
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {message?.message_media?.map((image, index) => (
                                                                                    <span key={index} onClick={() => setOverlayImage(image)}>
                                                                                        <AuthorSendImage message={image} messageTime={message.date_and_time?.slice(12)} />
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )
                                                                    ) : null}

                                                                    {message?.media_pdf?.length > 0 ? (
                                                                        message.user_id === user_id ? (
                                                                            <>
                                                                                {message?.media_pdf?.map((image, index) => (
                                                                                    <span key={index}>
                                                                                        <AuthorReceivePdf message={image} url={image.url} messageTime={message.date_and_time?.slice(12)} />
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {message?.media_pdf?.map((image, index) => (
                                                                                    <span key={index}>
                                                                                        <AuthorSendPdf message={image} url={image.url} messageTime={message.date_and_time?.slice(12)} />
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )
                                                                    ) : null}

                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    ))}

                                                </InfiniteScroll>
                                            </>
                                    }


                                    {chatAppendedMessages?.map((message, index) => (
                                        <React.Fragment key={index}>
                                            <div>
                                                {message.message_media?.length === 0 ? null : (
                                                    message.user_id === user_id ? (
                                                        message?.message_media?.map((image, index) => (
                                                            <AuthorSendImage message={image} messageTime={message.message_time?.slice(12)} key={index} />
                                                        ))
                                                    ) : (
                                                        message?.message_media?.map((image, index) => (
                                                            <AuthorReceiveImage message={image} messageTime={message.message_time?.slice(12)} key={index} />
                                                        ))
                                                    )
                                                )}

                                                {message.media_pdf?.length === 0 ? null : (
                                                    message.user_id === user_id ? (
                                                        message?.media_pdf?.map((image, index) => (
                                                            <AuthorSendPdf message={image} url={image.url} messageTime={message.message_time?.slice(12)} key={index} />
                                                        ))
                                                    ) : (
                                                        message?.media_pdf?.map((image, index) => (
                                                            <AuthorReceivePdf message={image} url={image.url} messageTime={message.message_time?.slice(12)} key={index} />
                                                        ))
                                                    )
                                                )}

                                                {!message.last_message ? null :
                                                    message.user_id === user_id ? <AuthorSendMessage message={message.last_message} messageTime={message.message_time?.slice(12)} />
                                                        : <AuthorReceiveMessage message={message.last_message} messageTime={message.message_time?.slice(12)} photo={currentChatUser?.thumbnail} />
                                                }
                                            </div>
                                        </React.Fragment>
                                    ))}

                                    {myInputMessages?.map((message, index) => (
                                        <React.Fragment key={index}>
                                            {!message.message ? null :
                                                <div>
                                                    {message.sender === 'user' && <AuthorSendMessage message={message.message} messageTime={message.time} />}
                                                </div>}

                                            {!message.message_media ? null :
                                                <div>
                                                    {message.sender === 'user' &&
                                                        message?.message_media?.map((image, index) => (
                                                            <AuthorSendImage message={URL?.createObjectURL(image)} messageTime={message.time} key={index} />
                                                        ))
                                                    }
                                                </div>}

                                            {!message.message_pdf ? null :
                                                <div>
                                                    {message.sender === 'user' &&
                                                        message?.message_pdf?.map((pdf, index) => (
                                                            <AuthorSendPdf message={pdf} messageTime={message.time} key={index} />
                                                        ))
                                                    }
                                                </div>}
                                        </React.Fragment>
                                    ))}
                                    {/* <div className='bg-red-500' style={{ height: '50px' }}></div> */}

                                    <div ref={messagesEndRef} style={{ height: '50px' }} />
                                </div>


                                {/* <div ref={messagesEndRef} className='' style={{height:'400px'}}/> */}
                            </div>

                            {keyboardOpen && <div className='mb-5'></div>}
                            {/* /-------------Body Section-------------/ */}


                            {/* /-------------Footer Section-------------/ */}
                            <div className=" border-t border-gray-200 bg-white fixed bottom-0 w-full shadow-lg z-50">
                                <div className="flex items-center space-x-3 w-full  p-4 px-2">
                                    <div className='flex max-w-3/4 w-full items-center gap-2  border p-4 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500' >
                                        <div
                                            onClick={() => { return setKeyboardOpen(true), setShowAttachment(false) }}
                                            contentEditable
                                            ref={inputRef}
                                            onInput={(e) => {
                                                e.preventDefault();
                                                const text = e.currentTarget.textContent;
                                                setChatInput(text);
                                            }}
                                            className="overflow-y-auto w-full overflow-x-hidden  xyz text-sm max-h-16  shadow-none border-0 border-transparent focus:outline-none focus:ring-2 focus:ring-transparent"
                                            suppressContentEditableWarning={true}
                                        ></div>

                                        <div className='cursor-pointer relative'>
                                            <span className='' onClick={() => setShowAttachment(!showAttachment)}  >
                                                <Icons.AttachmentIcon />
                                            </span>
                                        </div>

                                        <div className='w-8 '>
                                            <label htmlFor="image-upload" className="cursor-pointer">
                                                <CameraIcon />
                                            </label>
                                            <input
                                                id="image-upload"
                                                type="file"
                                                accept="image/*"
                                                capture="environment"  // Opens the camera directly on mobile devices
                                                className="hidden"
                                                onChange={handleImageUpload}  // Handle image selection
                                            />
                                        </div>
                                    </div>

                                    {!chatInput && (
                                        <span className="absolute text-gray-400  top-1/2 pl-1 transform -translate-y-1/2 pointer-events-none">
                                            Write your message
                                        </span>
                                    )}


                                    <button className='bg-green-500 rounded-full  min-w-10 h-10 p-2 flex items-center justify-center' style={{ width: '45px' }} onClick={submitMessages}>
                                        <img src={sendMessageIcon} alt="sendMessageIcon" className='w-6 h-6' />
                                    </button>

                                </div>

                                <div className="flex space-x-2  shadow-lg rounded-md bg-white border border-gray-300 p-10 duration-1000" style={{ position: 'absolute', top: '-130px', right: showAttachment ? '0px' : '-100%' }}>
                                    <div className='flex  gap-8' onClick={() => { return setSelectedImage([]), setSelectedPdf([]) }}>
                                        <div className="cursor-pointer gap-2" onClick={() => { return setMediaType('img'), galleryInputRef.current.click() }}>
                                            <div className='flex items-center justify-center'>

                                                <Icons.GalleryIcon />
                                            </div>
                                            <p className='text-sm text-gray-500' >
                                                Gallery
                                            </p>

                                            <input
                                                ref={galleryInputRef}
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                className="hidden"
                                                onChange={handleGalleryUpload}
                                            />
                                        </div>

                                        <div className="cursor-pointer gap-2" onClick={() => { return setMediaType('pdf'), documentInputRef.current.click() }}>
                                            <div className='flex items-center justify-center'>
                                                <Icons.DocumentIcon />
                                            </div>
                                            <p className='text-sm text-gray-500'>
                                                Document
                                            </p>

                                            <input
                                                ref={documentInputRef}
                                                type="file"
                                                accept=".pdf"
                                                // multiple
                                                className="hidden"
                                                onChange={handlePdfUpload}
                                            />
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="cursor-pointer gap-2" onClick={() => { return setMediaType('voice'), setShowImageModal(true) }}>
                                            <div className='flex items-center justify-center'>
                                                <Icons.VoiCeIcon />
                                            </div>
                                            <p className='text-sm text-center    text-gray-500'>
                                                Voice
                                            </p>

                                            
                                        </div> */}
                        {/* /-------------Footer Section-------------/ */}
                    </div>
                </div>

                <audio id="messageSound">
                    <source src={sendMessageTune} type="audio/mpeg" />
                </audio>


                <audio id="sendMessageTune">
                    <source src={seenMessageTune} type="audio/mpeg" />
                </audio>
                <OpenImageModal showModel={showImageModal}
                    setShowModel={setShowImageModal}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    inputRef={inputRef}
                    setChatInput={setChatInput}
                    chatInput={chatInput}
                    submitMessages={submitMessages}
                    selectedPdf={selectedPdf}
                    setSelectedPdf={setSelectedPdf}
                    mediaType={mediaType}
                />

                {overlayImage &&
                    <div id="overlay">
                        <span id="close-btn" onClick={() => setOverlayImage(null)}>&times;</span>
                        <img src={overlayImage} className="rounded-md" alt="" />
                    </div>
                }

            </>
        }
    </>
    );
};

export default ChatInterface;
