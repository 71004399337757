import React, {
    useEffect,
    useState
} from 'react';

import {
    useCustomDispatch,
    useCustomNavigate
} from '../hooks';

import { useAuthSelector } from '../services/selectors/authSelector';
import { verifyDriverOTP } from '../services/apis/auth/verifyOtp';

import { Loader } from '../components/common/loader';
import { removeValidateOTPData } from '../services/slices/auth/verifyOtp';

import Cookies from "js-cookie";
import * as Common from '../components/common';
import { authToken } from '../storage/authToken';

const DriverOtpVerification = () => {

    const token = authToken()
    const dispatch = useCustomDispatch()
    const navigate = useCustomNavigate()

    const [otp, setOtp] = useState('');

    const { driverSelectedPharmacy, driverOTPVerificationResponse, pharmacyId } = useAuthSelector()
    const { loading, error } = driverOTPVerificationResponse

    const handleSubmit = () => {
        const reqData = {
            pharmacy_id: pharmacyId,
            otp: otp,
            dispatch: dispatch
        }
        if (pharmacyId && otp) dispatch(verifyDriverOTP({ reqData, navigate }))
    }

    useEffect(() => {
        if (token && pharmacyId) {
            Cookies.set('verified', 0)
            dispatch(removeValidateOTPData())
        }
    }, [token, pharmacyId])

    return (
        <>
            <div className="app-wrapper flex items-center justify-center h-screen">
                <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full flex flex-col overflow-x-hidden">
                    <div className="relative p-4 w-full">
                        <Common.Header />

                        <div className='text-center'>
                            <div className="flex items-center gap-3 justify-center mt-20">
                                <div className="rounded-md flex items-center justify-center"
                                    style={{ width: '80px', height: '80px' }}>
                                    <img className='rounded-lg' style={{ width: '80px', height: '80px' }}
                                        src={`${driverSelectedPharmacy?.logo_full_url}?t=${new Date()?.getTime()}`}
                                        alt='pharmacy-logo'
                                    />
                                </div>

                            </div>
                            <h1 className="text-md font-bold mt-2">
                                {driverSelectedPharmacy?.name.toUpperCase()}</h1>
                        </div>

                        <div className="relative  mt-4 flex justify-center">
                            <input
                                key={'otp'}
                                type="text"
                                maxLength="6"
                                value={otp.slice(0, 6)}
                                inputMode="numeric"
                                placeholder='Enter OTP'
                                onChange={(e) => setOtp(e.target.value)}
                                className={`w-full ${error?.data?.message && 'border border-red-600'}  border border-gray-300 text-gray-500 rounded-md text-xl focus:ring-2 focus:ring-blue-300`}
                            />
                        </div>

                        {error?.data?.message === 'Invalid Otp' ?
                            <p className='text-red-600'>
                                Invalid OTP
                            </p> :
                            error?.data?.message === 'Expired Otp' ?
                                <p className='text-red-600'>
                                    The OTP has expired.
                                </p>
                                : null
                        }

                        <div className="relative mt-4">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className={`text-white flex items-center justify-center w-full focus:ring-4 focus:ring-blue-300 font-bold bg-blue-700 rounded-lg text-lg px-5 py-3 me-2 mb-2 
                        ${otp?.length !== 6 ? 'opacity-50 pointer-events-none' : ''}`}>
                                {loading ? <Loader /> : 'Verify OTP'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DriverOtpVerification;
