import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PDFIcon } from '../../assets/svg/icons';

export const AuthorSendMessage = ({ message, messageTime }) => {
    return (
        <div className="flex justify-end space-x-3">
            <div className="flex flex-col text-sm items-end">
                <div className="bg-green-500 text-white p-2 text-sm max-w-xs rounded-tl-lg rounded-br-lg  rounded-bl-lg">
                    {message}
                </div>
                <span className="text-xs text-nowrap w-full flex justify-end inline-block text-gray-400 mt-0.5" style={{ fontSize: '10px' }}>{messageTime}</span>
            </div>
        </div>
    )
}



export const AuthorReceiveMessage = ({ message, messageTime, name, photo }) => {
    return (
        <div className="flex space-x-3">
            {/* <img className="w-8 h-8 rounded-full" src={photo} alt={name} /> */}
            <div className="w-full">
                {/* <p className="font-semibold text-xs text-gray-900 text-nowrap">{name}</p> */}
                <div className="bg-gray-200 p-2 text-sm rounded-tr-lg rounded-br-lg rounded-b-lg max-w-xs text-gray-700 inline-block" style={{ maxWidth: '95%' }}>
                    {message}
                </div>

                <span className="text-xs text-gray-400 text-nowrap block mr-2" style={{ fontSize: '10px' }}>{messageTime}</span>
            </div>
        </div>
    )
}

export const AuthorSendImage = ({ message, messageTime }) => {
    return (
        <div className="flex justify-end space-x-3">
            <div className="flex flex-col text-sm items-end">
                <div className="border-1 bg-green-500 p-2 text-sm max-w-xs rounded-tl-lg rounded-br-lg  rounded-bl-lg">
                    {/* <img src={message} alt="message" className="w-full h-full object-cover rounded-md" /> */}

                    <LazyLoadImage
                        alt="message"
                        effect="opacity"
                        wrapperClassName="w-full h-full"
                        className="w-full h-full object-cover rounded-md"
                        threshold={100}
                        src={message}
                    />
                    <div className="flex justify-end"></div>
                </div>
                <span className="text-xs text-nowrap w-full flex justify-end inline-block text-gray-400 mt-0.5" style={{ fontSize: '10px' }}>{messageTime}</span>
            </div>
        </div>
    )
}

export const AuthorReceiveImage = ({ message, messageTime, name, photo }) => {
    return (
        <div className="flex space-x-3">
            <div className="w-full">
                <div className="bg-gray-200 p-2 text-sm rounded-tr-lg rounded-br-lg rounded-b-lg max-w-xs text-gray-700 inline-block" style={{ maxWidth: '95%' }}>
                    <LazyLoadImage
                        alt="message"
                        effect="opacity"
                        wrapperClassName="w-full h-full"
                        className="w-full h-full object-cover rounded-md"
                        threshold={100}
                        src={message}
                    />
                </div>

                <span className="text-xs text-gray-400 text-nowrap block mr-2" style={{ fontSize: '10px' }}>{messageTime}</span>
            </div>
        </div>
    )
}

export const AuthorSendPdf = ({ message, messageTime, url }) => {
    return (
        <div className="flex justify-end space-x-3">
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div className="flex flex-col text-sm items-end">
                    <div className="border-1 bg-green-500 p-2 text-sm max-w-xs rounded-tl-lg rounded-br-lg  rounded-bl-lg">
                        <PDFIcon />
                        <p className='text-sm text-white'>{message.name}</p>
                        <div className="flex justify-end"></div>
                    </div>
                    <span className="text-xs text-nowrap w-full flex justify-end inline-block text-gray-400 mt-0.5" style={{ fontSize: '10px' }}>{messageTime}</span>
                </div>
            </a>
        </div>
    )
}

export const AuthorReceivePdf = ({ message, messageTime, url }) => {
    return (
        <div className="flex space-x-3">
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div className="w-full">
                    <div className="bg-gray-200 p-2 text-sm rounded-tr-lg rounded-br-lg rounded-b-lg max-w-xs text-gray-700 inline-block" style={{ maxWidth: '95%' }}>
                        <PDFIcon />
                        <p className='text-sm'>{message.name}</p>
                    </div>
                    <span className="text-xs text-gray-400 text-nowrap block mr-2" style={{ fontSize: '10px' }}>{messageTime}</span>
                </div>
            </a>
        </div>
    )
}


export const InitatedChatBoxError = () => {
    return (
        <div className="flex justify-center items-center">
            <p className="text-red-500">🛠️ Oops! Looks like we hit a snag. Hang tight while we sort things out! Please try to load the chat again.</p>
        </div>
    )
}
