import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { driver_get_recent_chats } from "../../../networking/urlEndPoints";

export const getRecentChatsList = createAsyncThunk('getRecentChatsList', async (_, { rejectWithValue }) => {
    try {
        const response = await Axios.post(driver_get_recent_chats)
        const data = response.data;
        return data;
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const initialState = {
    loading: false,
    data: null,
    error: null
}

const getRecentChatsListSlice = createSlice({
    name: 'getRecentChatsList',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(getRecentChatsList.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(getRecentChatsList.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(getRecentChatsList.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default getRecentChatsListSlice.reducer