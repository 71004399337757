import {
	clearData,
	pendingDispenseRemovingPageNumber
} from "../../slices/dispense/patientPendingList";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_confirm_delivery } from "../../../networking/urlEndPoints";
import { fetchPatientPendingList } from "./patientPendingList";

import {
	clearAllTodayDispenseData,
	todayDispenseRemovingPageNumber
} from "../../slices/dispense/patientForRouteList";

import { fetchPatientlistOnBasisOfRoute } from "./patientsForRouteLIst";

import Axios from "../../../networking/interceptor";
import { fetchTodayMissed } from "./todayMissed";

export const confirmDelivery = createAsyncThunk('confirmDelivery', async (requesteData, { rejectWithValue }) => {

	try {

		const { pharmacy_id, pharmacist_id, patient_sign, pharmacist_sign, delivery_latitude, delivery_longitude, comment, is_refused, is_return, patient_id, isTransferRequest } = requesteData

		const datas = {
			pharmacy_id: pharmacy_id,
			// pharmacist_id: pharmacist_id,
			patient_sign: patient_sign,
			pharmacist_sign: pharmacist_sign,
			delivery_latitude: delivery_latitude,
			delivery_longitude: delivery_longitude,
			comment: comment,
			is_refused: is_refused,
			// covid19_exemptions: covid19_exemptions,
			is_return: is_return
		}

		if (isTransferRequest) {
			datas.to_pharmacy_id = pharmacy_id;
		}

		const response = await Axios.post(`${driver_confirm_delivery}/${patient_id}`, datas);
		const data = response

		if (typeof requesteData.setShowSuccessToast === 'function' && requesteData.type !== 'pending_dispense' && requesteData.type !== 'today_dispense') {

			requesteData.setShowSuccessToast(true)
			setTimeout(() => {
				requesteData.setShowSuccessToast(false)
			}, 3000);

		}

		if (requesteData.type === 'patient_search_list') {
			setTimeout(() => {
				window.history.back();
			}, 1000)
		}

		if (requesteData.type === 'today_missed') {
			if (pharmacy_id) {
				const params = {
					pharmacy_id: pharmacy_id,
					status: 'today_missed',
				};
				requesteData.dispatch(fetchTodayMissed(params))
			}
		}

		if (requesteData.type === 'final_missed') {
			if (pharmacy_id) {
				const params = {
					pharmacy_id: pharmacy_id,
					status: 'final_missed',
				};
				requesteData.dispatch(fetchTodayMissed(params))
			}
		}


		if (typeof requesteData.setOpenIndex === 'function') {
			requesteData.setOpenIndex(null)
		}

		if (typeof requesteData.setIsTransferRequest === 'function') {
			requesteData.setIsTransferRequest(null)
		}

		return data;

	} catch (error) {

		if (typeof requesteData.setShowErrorToast === 'function') {

			requesteData.setShowErrorToast(true)
			setTimeout(() => {
				requesteData.setShowErrorToast(false)
			}, 3000);
		}

		if (requesteData.type === 'pending_dispense') {
			if (typeof requesteData.setLoading === 'function') {
				requesteData.setLoading(true)
			}
			requesteData.dispatch(clearData())
			requesteData.dispatch(pendingDispenseRemovingPageNumber())
			requesteData.dispatch(fetchPatientPendingList({ page: 1, pharmacy_id: requesteData.pharmacy_id, setLoading: requesteData.setLoading }))
		}

		if (requesteData.type === 'today_dispense') {

			requesteData.dispatch(clearAllTodayDispenseData())
			requesteData.dispatch(todayDispenseRemovingPageNumber())
			if (typeof requesteData.params.setLoading === 'function') {
				requesteData.params.setLoading(true)
			}
			const params = {
				route: requesteData?.params?.route,
				pharmacy_id: requesteData?.pharmacy_id,
				setLoading: requesteData?.params?.setLoading,
			}
			requesteData.dispatch(fetchPatientlistOnBasisOfRoute(params))
		}
		return rejectWithValue(error.response)
	}
});