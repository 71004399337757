import React, { useState } from "react";

export const useCustomStates = () => {

	const [comment, setComment] = useState('');
	const [cordinate, setCordinate] = useState({ latitude: '', longitude: '' });

	const [openIndex, setOpenIndex] = useState(null);
	const [pharmacyData, setPharmacyData] = useState(null);

	const [patientSignature, setPatientSignature] = useState([null]);
	const [pharmacistSignature, setPharmacistSignature] = useState(null);

	const [showErrorToast, setShowErrorToast] = useState(false);
	const [loading, setLoading] = useState(false)
	const [showSuccessToast, setShowSuccessToast] = useState(false)

	const [showComment, setShowComment] = useState(false);
	const [showModel, setShowModel] = useState(false)

	const [showPatientSignature, setShowPatientSignature] = useState(false);
	const [showPharmacistSignature, setShowPharmacistSignature] = useState(false)

	const [showData, setShowData] = useState(false)
	const [apiCallMade, setApiCallMade] = useState(false)

	const [showToast, setShowToast] = useState(false)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)

	const [missedDeliverErrorToast, setMissedDeliveryErrorToast] = useState(false)


	const [missedDeliveryConfirmation, setMissedDeliveryConfirmation] = useState(false)
	const [missedData, setMissedData] = useState(null)
	const [patient_id, setPatient_id] = useState(null)
	const [isRefused, setIsRefused] = useState(false)

	const [signatureErrors, setSignatureErrors] = useState({ patientSign: null, pharmacistSign: null, returnPatches: null, pharmacistID: null })
	const [showSignatureToast, setShowSignatureToast] = useState(false);

	const [covid19, setCovid19] = useState('')


	const [selectedRoute, setSelectedRoute] = useState("");
	const [isTransferRequest, setIsTransferRequest] = useState(false)

	const [loadMore, setLoadMore] = useState(false)
	// const [showModel, setShowModel] = useState(false);
	const [delveryDoneByAnotherDriver, setDeliveryDoneByAnotherDriver] = useState(false)

	const [missedIndex, setMissedIndex] = useState(null)
	const [patientId, setPatientID] = useState(null)
	const [pId, setPId] = useState(null)
	const [pickupRoute, setPickupRoute] = useState(false)


	return {
		comment, setComment, cordinate, setCordinate,
		openIndex, setOpenIndex, pharmacyData, setPharmacyData,

		patientSignature, setPatientSignature, pharmacistSignature, setPharmacistSignature,
		showErrorToast, setShowErrorToast, loading, setLoading,
		showSuccessToast, setShowSuccessToast, showComment, setShowComment,

		showModel, setShowModel, showPatientSignature, setShowPatientSignature,
		showPharmacistSignature, setShowPharmacistSignature, showData, setShowData,
		apiCallMade, setApiCallMade, showToast, setShowToast,
		showConfirmationModal, setShowConfirmationModal, setMissedDeliveryErrorToast, missedDeliverErrorToast,

		missedDeliveryConfirmation, setMissedDeliveryConfirmation, missedData, setMissedData,
		patient_id, setPatient_id, setIsRefused, isRefused,

		signatureErrors, setSignatureErrors, showSignatureToast,
		setShowSignatureToast,
		covid19, setCovid19,

		selectedRoute, setSelectedRoute,
		isTransferRequest, setIsTransferRequest,
		loadMore, setLoadMore,
		delveryDoneByAnotherDriver, setDeliveryDoneByAnotherDriver,
		missedIndex, setMissedIndex,
		patientId, setPatientID,
		pId, setPId,

		pickupRoute, setPickupRoute


	}
}
