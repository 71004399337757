import { useSelector } from 'react-redux';
import * as EncryptedKey from '../constants/encryptedRootReducerKeys';

export const useAuthSelector = () => {

	const authLoginResponse = useSelector((state) => state[EncryptedKey.authLogin]);

	const authPharmacy = authLoginResponse?.data?.data?.pharmacist_profile?.pharmacy
	const authPharmacistId = authLoginResponse?.data?.data?.pharmacist_profile?.id

	const send_driver_delivery_otp = authLoginResponse?.data?.data?.pharmacist_profile?.pharmacy?.send_driver_delivery_otp

	const isdriverInstoreEnable = authLoginResponse?.data?.data?.driver_profile?.in_store

	const isSupervisor = authLoginResponse?.data?.data?.driver_profile?.is_supervisor
	const authEmail = authLoginResponse?.data?.data?.email;

	const driverDefaultPharmacist = authLoginResponse?.data?.data?.driver_profile?.driver_default_pharmacist

	const authPharmacies = authLoginResponse?.data?.data?.pharmacist_profile?.pharmacy

	const driverID = authLoginResponse?.data?.data?.driver_profile?.id

	const pharmacyId = authLoginResponse?.data?.data?.pharmacist_profile?.pharmacy?.id

	const instoreDefaultRoute = useSelector((state) => state?.instoreDefaultRoute?.data?.route)

	const verifyDriverPasscodeResponse = useSelector((state) => state.verifyDriverPasscode);

	// const driverSelectedPharmacy = useSelector(state => state?.driverSelectedPharmacy)
	const driverSelectedPharmacy = authLoginResponse?.data?.data?.pharmacist_profile?.pharmacy

	const driverSelectedPharmacist = useSelector(state => state.driverSelectedPharmacist)
	const selectedPharmacistID = driverSelectedPharmacist?.data

	const pharmacyDetail = useSelector((state) => state[EncryptedKey.pharmacyDetail])
	const pharmacyInspectionMode = pharmacyDetail?.data?.data?.inspection_mode

	const SOS_EmergencyEnabledResponse = useSelector(state => state.SOS_EmergencyEnabled)
	const driverOTPVerificationResponse = useSelector(state => state.driverOTPVerification)

	return {
		authLoginResponse,
		verifyDriverPasscodeResponse,
		authEmail,
		authPharmacies,
		driverSelectedPharmacy,
		pharmacyId,
		driverSelectedPharmacist,
		selectedPharmacistID,
		driverID,
		pharmacyInspectionMode,
		SOS_EmergencyEnabledResponse,
		isSupervisor,
		authPharmacy,
		driverDefaultPharmacist,
		isdriverInstoreEnable,
		instoreDefaultRoute,
		driverOTPVerificationResponse,
		send_driver_delivery_otp,
		authPharmacistId
	}
}