import React from 'react';
import { useAuthSelector } from '../../services/selectors/authSelector';

import * as Hooks from '../../hooks';
import * as routeName from '../../routes/routeName';
import * as Icons from '../../assets/svg/icons';

const PharmacyFrontProfile = () => {

	const navigate = Hooks.useCustomNavigate();

	const { driverSelectedPharmacy } = useAuthSelector()

	const tableData = [{
		heading: 'Manager',
		data: driverSelectedPharmacy?.manager_name,
		icon: <Icons.ManagerIcon />
	}, {
		heading: 'Email',
		data: driverSelectedPharmacy?.email,
		icon: <Icons.EmailIcon2 />
	},
	{
		heading: 'Phone',
		data: driverSelectedPharmacy?.phone,
		icon: <Icons.PhoneIcon2 />
	},
	{
		heading: 'Fax',
		data: driverSelectedPharmacy?.fax,
		icon: <Icons.FaxIcon />
	}]


	return (
		<React.Fragment>
			<div className="app-wrapper flex justify-center h-screen">
			{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}

				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden "> */}
				{/* <div className="app-screen no-shades w-full xl:w-6/12 lg:w-4/12 md:w-6/12 sm:w-8/12 h-full md:h-auto overflow-y-hidden md:overflow-y-auto overflow-x-hidden"> */}
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden h-screen">				
					<div className="relative p-4">
						<div className="flex items-center gap-3">
							<div className="text-info flex-1">
								<h1 className="text-lg font-bold">Contact Pharmacy</h1>
							</div>
							<button onClick={() => navigate(`${routeName.DASHBOARD_PHARMACY}`)}>
								<Icons.HomeIcon />
							</button>
						</div>
						<div className="card flex flex-col items-center bg-white p-4 mt-3 rounded-lg shadow-sm">
							<div className="rounded-md flex items-center justify-center"
								style={{ width: ' 80px', height: '80px' }}>
								<img className='rounded-lg' src={driverSelectedPharmacy?.logo_full_url} />
							</div>
							<h1 className="text-xl font-bold mt-2">{driverSelectedPharmacy?.name.toUpperCase()}</h1>
							<span className="text-gray-400 text-sm">{driverSelectedPharmacy?.city}, {driverSelectedPharmacy?.country}</span>
							<div className="mt-4 px-20 text-center flex flex-col items-center">
								<p className="text-sm">{driverSelectedPharmacy?.full_address}</p>
								<button
									className="p-2 mt-2 flex items-center gap-2 bg-gray-400 bg-opacity-10 rounded-md uppercase">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_5242_1721)">
											<path fillRule="evenodd" clipRule="evenodd"
												d="M8.89882 0.292626C8.30001 0.103464 7.66308 0 7.00163 0C5.07178 0 3.34475 0.872371 2.19336 2.24284L5.16023 4.73883L8.89876 0.292572L8.89882 0.292626Z"
												fill="#1A73E8" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M2.19188 2.2428C1.27458 3.3343 0.720703 4.74289 0.720703 6.27941C0.720703 7.46072 0.956143 8.41749 1.34262 9.2749L5.15875 4.73879L2.19188 2.2428Z"
												fill="#EA4335" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M7.00095 3.87875C8.3279 3.87875 9.40304 4.95389 9.40304 6.28083C9.40304 6.87148 9.18936 7.41316 8.83555 7.83096C8.83555 7.83096 10.7327 5.57451 12.5741 3.38605C11.8133 1.92303 10.4945 0.797503 8.89949 0.292603L5.1582 4.73886C5.59917 4.2122 6.26061 3.87875 7.00095 3.87875Z"
												fill="#4285F4" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M7.00068 8.68148C5.67374 8.68148 4.59859 7.60634 4.59859 6.2794C4.59859 5.6928 4.80952 5.15523 5.15793 4.73743L1.3418 9.27489C1.99369 10.7216 3.07838 11.8825 4.19575 13.3455L8.83387 7.83087C8.39426 8.35079 7.73557 8.68148 7.00068 8.68148Z"
												fill="#FBBC04" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M8.74288 14.8508C10.8374 11.575 13.2803 10.0875 13.2803 6.27949C13.2803 5.23561 13.0244 4.25168 12.574 3.38611L4.19727 13.3456C4.55248 13.8111 4.91039 14.3064 5.26015 14.8522C6.53401 16.8202 6.18015 18.0001 7.00219 18.0001C7.82148 18.0001 7.46897 16.8188 8.74288 14.8508Z"
												fill="#34A853" />
										</g>
										<defs>
											<clipPath id="clip0_5242_1721">
												<rect width="12.5603" height="18" fill="white"
													transform="translate(0.720703)" />
											</clipPath>
										</defs>
									</svg>
									<a href={driverSelectedPharmacy?.map_url} target='_blank'> Direction</a>
								</button>
							</div>
							<div className="card border rounded-xl p-4 w-full mt-4">
								<div className="card-header pb-4 border-b border-dashed border-gray-300 font-bold text-xl">
									<h3>Information</h3>
								</div>
								<div className="card-body pt-2">
									{tableData?.map((value, index) => (
										<React.Fragment key={index}>
											<div className="flex items-center gap-2 mt-3">

												{value?.icon}
												<span className="text-sm w-4/12 text-gray-400">{value?.heading}</span>
												<span className="text-sm text-gray-800">{value?.data}</span>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</React.Fragment >
	)
}

export default PharmacyFrontProfile