import { useSelector } from 'react-redux';

export const useChatBoxSelector = () => {

    const getRecentChatsListResponse = useSelector(state => state.getRecentChatsList)
    const getContactListResponse = useSelector(state => state.getContactList)
    const getInitatedChatBoxResponse = useSelector(state => state.getInitatedChatBox)
    const addmessagetogroupResponse = useSelector(state => state.addmessagetogroup)
    const readUnreadMessagesResponse = useSelector(state => state.readUnreadMessages)
    const getActivityStatusResponse = useSelector(state => state.getActivityStatus)

    return {
        getRecentChatsListResponse,
        getContactListResponse,
        getInitatedChatBoxResponse,
        addmessagetogroupResponse,
        readUnreadMessagesResponse,
        getActivityStatusResponse
    }
}