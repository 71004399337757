import React, {
	useEffect,
	useState
} from 'react';

import {
	PatientDetails,
	PatientMobileAndLocation
} from '../../components/common/patientDetails';

import { LoadingView } from '../../components/common/loader';
import { fetchPatientDeliveredList } from '../../services/apis/supervisor/deliveredDispense';

import { ErrorMessages } from '../../components/error/errorMessages';

import { useCustomStates } from '../../hooks/useCustomStates';
import { DispenseRxData } from '../../components/common/dispenseRxData';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { clearDeliveredDispenseData } from '../../services/slices/supervisor/patientDeliveredList';
import { useSuperVisorSelector } from '../../services/selectors/allSuperVisorSelector';

import SearchBox from '../../components/common/searchBox';
import InfiniteScroll from 'react-infinite-scroll-component';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeName from '../../routes/routeName';

const DeliveredPatient = () => {

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const { pharmacyId } = useAuthSelector()

	const { patientDeliveredListResponse } = useSuperVisorSelector();
	const patientsDeliveredData = patientDeliveredListResponse

	const { openIndex, setOpenIndex, apiCallMade, setApiCallMade, loading, setLoading } = useCustomStates()

	const [loadMore, setLoadMore] = useState(false)

	useEffect(() => {
		if (pharmacyId) {
			const params = {
				pharmacy_id: pharmacyId,
				page: 1,
				setLoading: setLoading
			}

			if (!apiCallMade) {
				setLoading(true)
				dispatch(clearDeliveredDispenseData())
				dispatch(fetchPatientDeliveredList(params))
				setApiCallMade(true)
			}
		}
		return () => { }
	}, [])

	const handleDropdownToggle = (index) => {
		if (openIndex === index) {
			setOpenIndex(null);
		} else {
			setOpenIndex(index);
		}

		setTimeout(() => {
			const nextPatientCard = document.getElementById(`patientCard-${index}`);
			if (nextPatientCard) {
				nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		}, 100);
	};

	const fetchMoreData = () => {
		if (patientsDeliveredData?.currentPage < patientsDeliveredData?.totalPages && pharmacyId) {
			setLoadMore(true)
			const params = {
				pharmacy_id: pharmacyId,
				page: patientsDeliveredData?.currentPage + 1,
				setLoadMore: setLoadMore
			}
			dispatch(fetchPatientDeliveredList(params))
		}
	}

	return (
		<>
			{patientsDeliveredData?.data?.length > 0 &&
				<InfiniteScroll
					dataLength={patientsDeliveredData?.data?.length}
					next={fetchMoreData}
					hasMore={patientsDeliveredData?.currentPage <= patientsDeliveredData?.totalPages}
				/>
			}

			<div className="app-wrapper flex justify-center">
				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden "> */}
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}
				<div className="app-screen no-shades w-full h-full  overflow-x-hidden ">

					<div className="relative p-4" >
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">
								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>
								<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeName.DASHBOARD_PHARMACY}`)}>
									<Icons.HomeIcon />
								</div>
							</div>
						</div>

						<div className="title-row my-2" >
							<h1 className="text-xl font-bold">Delivered Dispense</h1>
						</div>

						<div className="flex gap-3 mb-3">
							<SearchBox pharmacyId={pharmacyId} />
						</div>

						<div className="routes-list">

							{(patientDeliveredListResponse?.loading && loading) ?
								<div className='h-96 flex justify-center'>
									<LoadingView /></div> :
								patientDeliveredListResponse?.error !== null ?
									<div className='flex  justify-center pb-4 mb-4 '>
										<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
									</div> :
									patientsDeliveredData?.data?.length === 0 ?
										<p className='flex justify-center pb-4 pt-3 h-96'>No Delivered Dispense yet.</p>
										:
										patientsDeliveredData?.data?.map((data, index) => (
											<React.Fragment key={index}>
												<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>
													<PatientDetails index={index} data={data} patientImg={data.profile_image} totalRxData={data?.patients_win_rx_data_delivered?.length} />
													<div className="flex items-center justify-between mt-4">
														<PatientMobileAndLocation data={data} />

														<button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index)}>
															{openIndex === index ?
																<Icons.DropdownBoxIcon2 /> :
																<Icons.DropdownBoxIcon1 />}
														</button>
													</div>
													{openIndex === index &&
														<>
															<DispenseRxData rxData={data?.patients_win_rx_data_delivered} patientData={data} />
														</>}
												</div>

											</React.Fragment>))}
						</div>

						{(patientDeliveredListResponse?.loading && loadMore) &&
							<div className='flex mb-2'>
								<div className="loaderss"></div>
							</div>
						}

					</div>
				</div>
			</div>

		</>
	)
}

export default DeliveredPatient
