import React, { useState, useEffect } from 'react'

import {
    PatientDetails,
    PatientMobileAndLocation
} from '../../components/common/patientDetails';

import {
    deleteAllPatientList,
    filterPatientsByPharmacistSign
} from '../../services/slices/supervisor/patientWithoutPharmacistSign';

import {
    ErrorMessage,
    SuccessMessage
} from '../../components/common/AllConfirmationMessages';

import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { ErrorMessages } from '../../components/error/errorMessages';

import { PendingPharmacistSignRxData } from '../../components/common/dispenseRxData';
import { useSuperVisorSelector } from '../../services/selectors/allSuperVisorSelector';
import { fetchPatientListWithoutPharmacistSign } from '../../services/apis/supervisor/patientWithoutPharmacistSign';

import { useCustomStates } from '../../hooks/useCustomStates';
import { useAuthSelector } from '../../services/selectors/authSelector';
import { UpdatePharmacistSignature } from '../../services/apis/supervisor/updatePharmacistSign';

import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';

import * as Loading from '../../components/common/loader'
import * as routeNames from '../../routes/routeName'

const PendingPharmacistSignatures = () => {

    const { pharmacyId } = useAuthSelector()
    const { PatientListWithoutPharmacistSignResponse } = useSuperVisorSelector()
    const patientList = PatientListWithoutPharmacistSignResponse?.data

    const { openIndex, setOpenIndex, setPharmacistSignature, pharmacistSignature,
        loading, setLoading, showPharmacistSignature, setShowPharmacistSignature,
        signatureErrors, setSignatureErrors, apiCallMade, setApiCallMade,
    } = useCustomStates()

    const [loadMore, setLoadMore] = useState(false)
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

    const dispatch = Hooks.useCustomDispatch();
    const navigate = Hooks.useCustomNavigate();

    const handleDropdownToggle = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }

        setPharmacistSignature(null)
        setShowPharmacistSignature(false)
        setTimeout(() => {
            const nextPatientCard = document.getElementById(`patientCard-${index}`);
            if (nextPatientCard) {
                nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 100)
        setSignatureErrors(prevState => ({ ...prevState, pharmacistSign: null }))
    }

    useEffect(() => {
        if (pharmacyId) {
            setLoading(true)
            if (!apiCallMade) {
                dispatch(deleteAllPatientList())
                const params = {
                    pharmacy_id: pharmacyId,
                    page: 1,
                    setLoading: setLoading
                }
                dispatch(fetchPatientListWithoutPharmacistSign(params))
                setApiCallMade(true)
            }
        }
        return () => { }
    }, [])

    const fetchMoreData = () => {
        if (PatientListWithoutPharmacistSignResponse?.currentPage < PatientListWithoutPharmacistSignResponse?.totalPages) {
            setLoadMore(true)
            setLoading(false)
            const params = {
                pharmacy_id: pharmacyId,
                page: PatientListWithoutPharmacistSignResponse?.currentPage + 1,
                setLoading: setLoading,
                setLoadMore: setLoadMore
            }
            dispatch(fetchPatientListWithoutPharmacistSign(params))
        }
    }

    const updatePharmacistSignature = (id, index) => {
        if (!pharmacistSignature) {
            setSignatureErrors(prevState => ({ ...prevState, pharmacistSign: '1px solid red' }));
        }
        else {
            const requestedData = {
                pharmacy_id: pharmacyId,
                signature: pharmacistSignature,
                patient_id: id,
                setShowToast: setShowToast,
                dispatch: dispatch,
                setLoading: setLoading
            }
            dispatch(filterPatientsByPharmacistSign(id))
            setOpenIndex(null);
            setPharmacistSignature(null)
            setShowPharmacistSignature(false)
            dispatch(UpdatePharmacistSignature(requestedData))
            setSignatureErrors(prevState => ({ ...prevState, pharmacistSign: null }));
            setTimeout(() => {
                const nextIndex = index;
                const nextPatientCard = document.getElementById(`patientCard-${nextIndex}`);
                if (nextPatientCard) {
                    nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 100);
            setShowToast(prevState => ({ ...prevState, successToast: true }));

            setTimeout(() => {
                setShowToast(prevState => ({ ...prevState, successToast: false }))
            }, 2000);
        }
    }

    const handleScrollSign = (index) => {
        setTimeout(() => {
            const nextPatientCard = document.getElementById(`signature-${index}`);
            if (nextPatientCard) {
                nextPatientCard.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 100)
    }

    return (<React.Fragment>

        {patientList?.length > 0 &&
            <InfiniteScroll
                dataLength={patientList?.length}
                next={fetchMoreData}
                hasMore={PatientListWithoutPharmacistSignResponse?.currentPage <= PatientListWithoutPharmacistSignResponse?.totalPages}
            />
        }

        <div className="app-wrapper flex justify-center">
            <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden">
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}

				{/* <div className="app-screen no-shades w-full xl:w-6/12 lg:w-4/12 md:w-6/12 sm:w-8/12 h-full md:h-auto overflow-y-hidden md:overflow-y-auto overflow-x-hidden"> */}

                {/* <div className='flex items-center justify-center'>
                    <SOSemergencyEnableButton />
                </div> */}

                <div className="relative p-4" >
                    <div className="card bg-white p-4 rounded-lg shadow-sm">

                        <div className="flex items-center justify-between gap-3">
                            <div>
                                <Common.TopBar pharmacyId={pharmacyId} />
                            </div>
                            <div className='flex items-center justify-center btnnn pt-4'
                                onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)} >
                                <Icons.HomeIcon />
                            </div>
                        </div>
                    </div>

                    <div className="title-row my-2" >
                        <h1 className="text-xl font-bold">Pending Signatures</h1>
                    </div>

                    <div className="flex gap-3 mb-3">
                        <SearchBox pharmacyId={pharmacyId} />
                    </div>

                    <div className="routes-list">
                        {(PatientListWithoutPharmacistSignResponse?.loading && loading) ?
                            <div className='flex justify-center h-96'>
                                <Loading.LoadingView />
                            </div> :

                            PatientListWithoutPharmacistSignResponse?.error !== null ?
                                <div className='flex  justify-center pb-4 mb-4'>
                                    <ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
                                </div> :

                                (patientList?.length === 0 && !PatientListWithoutPharmacistSignResponse?.loading) ?
                                    <p className='flex  justify-center pb-4 pt-3 h-96'>No more pending signatures for today.</p>
                                    :
                                    patientList?.map((data, index) => (
                                        <React.Fragment key={index}>
                                            {data?.patients_win_rx_data_delivered?.length === 0 || data?.patients_win_rx_data_delivered === null ? null :
                                                <div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>

                                                    <PatientDetails data={data} totalRxData={data?.patients_win_rx_data_delivered?.length} index={index} patientImg={data.profile_image} />

                                                    <div className="flex items-center justify-between mt-4">
                                                        <PatientMobileAndLocation data={data} />
                                                        <button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index, data)}>
                                                            {openIndex === index ?
                                                                <Icons.DropdownBoxIcon2 /> :
                                                                <Icons.DropdownBoxIcon1 />}
                                                        </button>
                                                    </div>

                                                    {openIndex === index && <>

                                                        <PendingPharmacistSignRxData rxData={data?.patients_win_rx_data_delivered} patientData={data}/>

                                                        <div className='' onClick={() => handleScrollSign(index)} id={`signature-${index}`}>
                                                            <Common.PharmacistSignature
                                                                pharmacistSignature={pharmacistSignature}
                                                                setPharmacistSignature={setPharmacistSignature}
                                                                showPharmacistSignature={showPharmacistSignature}
                                                                setShowPharmacistSignature={setShowPharmacistSignature}
                                                                signatureErrors={signatureErrors}
                                                                setSignatureErrors={setSignatureErrors} />
                                                        </div>

                                                        <button onClick={() => updatePharmacistSignature(data.id, index)} className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md w-full">
                                                            Continue
                                                        </button>
                                                    </>}

                                                </div>}

                                        </React.Fragment>))}
                    </div>
                </div>

                {loadMore &&
                    <div className='flex mb-2'>
                        <div className="loaderss"></div>
                    </div>}

						<div style={{ height: '100px' }}></div>
                    

                {showToast.successToast && <SuccessMessage message='Signature Updated Successfully' setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />}

                {showToast.errorToast && <ErrorMessage message='Oops! Got an issue while updating sign.please try again.' setShowToast={() => setShowToast(prevState => ({ ...prevState, errorToast: false }))} />}

            </div>
        </div>
    </React.Fragment>
    )
}

export default PendingPharmacistSignatures