import { useEffect } from 'react';
import { apiKey, chatChannelName } from '../../networking/urlEndPoints';
import { getRecentChatsList } from '../../services/slices/chat/getRecentChatsList';
import Ably from 'ably';

export const scrollToBottom = (messagesEndRef) => {
    if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
};

export const scrollToTop = (chatContainerRef) => {
    if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // Scroll to the top of the reversed column
    }
};

export const scrollToTopWithPadding = (chatContainerRef, padding = 0) => {
    if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - padding; // Scroll to the top of the reversed column with padding
    }
};

export const handleViewportResize = (headerRef) => {
    const handleResize = () => {
        if (headerRef.current) {
            const viewportHeight = window.visualViewport.height;
            headerRef.current.style.top = `${window.visualViewport.offsetTop}px`;
            headerRef.current.style.height = `${Math.min(64, viewportHeight * 0.1)}px`;
        }
    };

    window.visualViewport.addEventListener('resize', handleResize);
    window.visualViewport.addEventListener('scroll', handleResize);

    return () => {
        window.visualViewport.removeEventListener('resize', handleResize);
        window.visualViewport.removeEventListener('scroll', handleResize);
    };
};

export const subscribeGroupMessages = (group_id, setChatAppendedMessages, setMyInputMessages, authPharmacistId, playMessageSound) => {

    const ably = new Ably.Realtime({ key: apiKey });
    const channel = ably.channels.get(chatChannelName);
    const sendChatMessages = `message-group-${group_id}`;

    channel.subscribe(sendChatMessages, async (message) => {
        if (message.name === sendChatMessages) {
            if (message?.data?.user_id === authPharmacistId) {
                // if (typeof playMessageSound === 'function') {
                //     playMessageSound()
                // }
            }
            // setChatAppendedMessages((prev) => [...prev, message?.data])

            setChatAppendedMessages((prev) => {
                const messageExists = prev.some((msg) => msg.message_id === message.data.message_id);

                if (!messageExists) {
                    return [...prev, message?.data];
                }

                return prev;
            });
            setMyInputMessages([])
        }
    })
    return ably
}

export const SendMessageNotification = (pharmacyId, user_id, dispatch, location, setShowData, setShowNotification, playMessageSound) => {

    const ably = new Ably.Realtime({ key: apiKey });
    const channel = ably.channels.get('oat-pharmacy-chat-notification');
    const sendChatMessages = `message-notification-${pharmacyId}-${user_id}`;

    channel.subscribe(sendChatMessages, async (message) => {
        if (message.name === `message-notification-${pharmacyId}-${user_id}`) {
            if (location?.pathname.startsWith('/initated-chat')) {
                // dispatch(getRecentChatsList())
            } else {
                if (typeof playMessageSound === 'function') {
                    playMessageSound()
                }

                dispatch(getRecentChatsList())
                setShowData(message?.data)
                setShowNotification(true)
                setTimeout(() => {
                    setShowNotification(false)
                }, 5000)
            }

        }
    })
    return ably
}


export const sendNotification = async (title, body, icon) => {
    if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notification");
        return;
    }

    let permission = Notification.permission;

    if (permission === "default") {
        permission = await Notification.requestPermission();
    }

    if (permission === "granted") {
        const notification = new Notification(title, {
            body: body,
            icon: icon || 'https://your-default-icon-url.png',
            tag: 'react-notification'
        });

        notification.onclick = () => {
            window.focus();
            notification.close();
        };
    } else {
        console.warn("Notification permission denied");
    }
};


// export const usePreventZoom = () => {
//     useEffect(() => {
//         // Prevent zooming on mobile devices
//         const preventZoom = (e) => {
//             if (e.touches.length > 1) {
//                 e.preventDefault();
//             }
//         };

//         let lastTouchEnd = 0;
//         const preventDoubleTapZoom = (e) => {
//             const now = (new Date()).getTime();
//             if (now - lastTouchEnd <= 300) {
//                 e.preventDefault();
//             }
//             lastTouchEnd = now;
//         };

//         document.addEventListener('touchstart', preventZoom, { passive: false });
//         document.addEventListener('touchend', preventDoubleTapZoom, false);

//         // Clean up event listeners
//         return () => {
//             document.removeEventListener('touchstart', preventZoom);
//             document.removeEventListener('touchend', preventDoubleTapZoom);
//         };
//     }, []);
// };


export const usePreventZoom = () => {
    useEffect(() => {
        // Function to disable pinch-to-zoom and double-tap zoom
        const preventZoom = (event) => {
            if (event.touches.length > 1 || event.ctrlKey) {
                event.preventDefault();
            }
        };

        // Function to disable zoom on wheel scroll
        const preventWheelZoom = (event) => {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        };

        // Disable double-tap zoom
        document.addEventListener('touchstart', preventZoom, { passive: false });

        // Disable pinch-to-zoom
        document.addEventListener('gesturestart', preventZoom);

        // Disable zoom on wheel scroll
        document.addEventListener('wheel', preventWheelZoom, { passive: false });

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('touchstart', preventZoom);
            document.removeEventListener('gesturestart', preventZoom);
            document.removeEventListener('wheel', preventWheelZoom);
        };
    }, []);
};
