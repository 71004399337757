import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { driver_chat_initated } from "../../../networking/urlEndPoints";

export const getInitatedChatBox = createAsyncThunk('getInitatedChatBox', async (requestData, { rejectWithValue }) => {
    try {
        const response = await Axios.post(driver_chat_initated, requestData)
        const data = response.data;
        return data;
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const initialState = {
    loading: false,
    data: [],
    error: null,
    allMessages: {},
    currentPage: 1,
    totalPages: null,
    onlineStatus: null,
}


const getInitatedChatBoxSlice = createSlice({
    name: 'getInitatedChatBox',
    initialState,

    reducers: {
        removeAllMessages: (state) => {
            return {
                ...state,
                allMessages: {},
                data: [],
                currentPage: 1,
                totalPages: null,
                onlineStatus: null,
            }
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getInitatedChatBox.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: [],
                error: null,
            }
        })


        builder.addCase(getInitatedChatBox.fulfilled.type, (state, action) => {
            const newUpdatedMessages = action.payload.data.messages;

            // Copy existing state messages
            const mergedMessages = { ...state.allMessages };

            // Iterate over each date in the new messages
            Object.keys(newUpdatedMessages).forEach(dateKey => {
                if (mergedMessages[dateKey]) {
                    // Merge new messages with existing ones for the date
                    const combinedMessages = [...mergedMessages[dateKey], ...newUpdatedMessages[dateKey]];

                    // Remove duplicates based on 'id' field
                    const uniqueMessages = Array.from(new Set(combinedMessages.map(msg => msg.id)))
                        .map(id => combinedMessages.find(msg => msg.id === id));

                    // Sort the messages by 'id' in ascending order
                    mergedMessages[dateKey] = uniqueMessages.sort((a, b) => a.id - b.id);
                } else {
                    // If the date doesn't exist, add the new messages and sort by 'id'
                    mergedMessages[dateKey] = newUpdatedMessages[dateKey].sort((a, b) => a.id - b.id);
                }
            });

            return {
                ...state,
                loading: false,
                data: action.payload,
                allMessages: mergedMessages, // Update with sorted messages
                currentPage: action.payload.data.pagination.current_page,
                lastPage: action.payload.data.pagination.last_page,
                onlineStatus: action.payload.data.users,
                error: null
            };
        });



        builder.addCase(getInitatedChatBox.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload,
                allMessages: {},
                onlineStatus: null,
            }
        })
    }

})

export const { removeAllMessages } = getInitatedChatBoxSlice.actions;

export default getInitatedChatBoxSlice.reducer