import React from 'react'

export const Chatloader = () => {
    return (
        <React.Fragment>
            <div className="flex items-start gap-3 p-1 pt-2 pb-0">
                <svg className="w-8 h-8  text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div className="rounded-md">
                    <div className="h-12 rounded-54 bg-gray-300 dark:bg-gray-700 w-32 mb-3" style={{ borderRadius: '4px' }}></div>
                </div>
            </div>

            <div className="flex items-start gap-3  pb-0 justify-end" >
                <div className="rounded-md ">
                    <div className="h-12 rounded-54 bg-gray-300 dark:bg-gray-700 w-52 mb-3" style={{ borderRadius: '4px' }}></div>
                </div>
            </div>
        </React.Fragment>
    )
}
