export const BlackListData = [
	'changePassword',
	'changePasscode',
	'updateProfile',
	'driverLogout',
	'patientList',
	'patientSearchList',
	'patientDeliveryRxList',
	'patientPendingList',
	'confirmDelivery',
	// 'finalMissedList',
	'updateDeliveryList',
	'patientConsent',
	'allPharmacyList',
	"SOS_EmergencyEnabled",
	// 'patientDataBasisOfRoute',
	// ,'switchPharmacy',
	"todaysAnalytics",
	"UpdateNewRXDataOption",
	"patientNewRxData",
	"UploadPrescripition",
	"PatientListWithoutPharmacistSign",
	"UpdatePharmacistSignature",
	"updatePatientProfile",
	"updatedDefaultPharmacist",
	"UploadedTriplicatesPatientSign",
	"validateReturnedPatches",
	"getInitatedChatBox",
	"addmessagetogroup",
	"readUnreadMessages",
	"getActivityStatus"
]