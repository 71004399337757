import React from 'react';

import { driverLogout } from '../../services/apis/pharmacy/logout';
import { useAllSelector } from '../../services/selectors/allSelector';

import * as Icons from '../../assets/svg/icons';
import * as Hooks from '../../hooks';

export const BottomBar = () => {

    const dispatch = Hooks.useCustomDispatch();

    const { driverLogoutResponse } = useAllSelector();

    return (
        <>
            <div className="actions flex divide-x-2 items-center justify-around my-4 mt-4">
                {/* <button className="flex flex-1 justify-right gap-2 items-center" onClick={() => dispatch(driverLogout())}>
                    <Icons.LogoutIcon />
                    <span className="text-lg font-medium"  >{driverLogoutResponse?.loading ? 'Processing...' : 'Log out'} </span>
                </button> */}
            </div>

        </>
    )
}
